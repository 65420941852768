import { Box, Heading, Link, Text } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import React, { useEffect } from 'react'
import Wrapper from '../components/Wrapper'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <Wrapper>
      <Box maxW={'800px'} mx={'auto'}>
        <Heading pb={4}>Vaselabs Private Policy</Heading>
        <Text lineHeight={7}>
          This Private Policy ("Policy") explains how Vaselabs Ecosystem and its affiliate ( collectively, "Vaselabs," "our," "we," or "us") collects, uses, and discloses information about you. This Policy applies when you use <Link href='www.vaselabs.io' color={'blue.400'} textDecor={'underline'} >www.vaselabs.io</Link> (the "Website"), engage with us on social media, and any other related and connected platforms and other online and offline interactions, or otherwise interact with us (collectively, the “services”).
        </Text>
        <Text lineHeight={7} pt={4}>
          We may change this Policy from time to time. If we make changes, we may notify you by revising the date at the top of this policy, we may provide you with additional notice (such as adding a statement to our Website or sending you a notification). We encourage you to review this Policy regularly to stay informed about our information practices and the choices available to you. By using our Services, you accept and agree to the practices described in this Policy. If you do not agree with this Policy, please do not use the Services or provide us with any Personal Information (as defined below).
          <Link
            _hover={{ bgColor: 'blue.600' }}
            py={1}
            rounded={3}
            display={'inline-block'}
            ml={3}
            px={4}
            bgColor={'blue.500'}
            target={'_blank'}
            href={'https://vaselabs.gitbook.io/vaselabs-privacy-policy/vaselabs-private-policy/contents'}> Read more<ArrowForwardIcon ml={3} /></Link>
        </Text>
      </Box>
    </Wrapper>
  )
}

export default PrivacyPolicy