import { Badge, Box, Button, Flex, Heading, Img, Input, Text, VStack, useDisclosure, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import step2image from '../assets/twStep2.png'
import CustomModal from '../customModal'
import { apiInstance, endpoints } from '../../../endpoint'
import { convertBool, verificationLink } from '../../../utils'

const ContentModal = (props) => {

  const { type, id: task_id, onClose, point, content } = props
  const [link, setLink] = useState()
  const toast = useToast()

  const handleLinkVerification = () => {
    const userObj = localStorage.getItem('userObject')
    const { email, id, username } = JSON.parse(userObj)

    const { success } = verificationLink(username.toLowerCase()).safeParse(link)
    if (!success) return toast({ status: 'error', title: 'Invalid Link' })

    return apiInstance.post(endpoints.extraTasks,
      { email, point, type, link, id, task_id, col: 'vase_post_content' }
    ).then(() => toast({ status: 'success', title: 'Verifying Link' })).finally(onClose)
  }

  return (
    <Box p={4}>
      <VStack spacing={6}>
        <Flex gap={4} w={'100%'} justify={'space-between'} alignItems={'center'}>
          <Flex flexDir={'column'} gap={3} maxW={'600px'}>
            <Heading size={'md'}>Instruction</Heading>
            <Text>{content}</Text>
            <Input required placeholder="Paste link here" value={link} onChange={e => setLink(e.target.value)} />
            <Box>
              <Button disabled={!link} onClick={handleLinkVerification} colorScheme="twitter" px={8}>Verify</Button>
            </Box>
          </Flex>
          <Box w={'120px'} display={['none', 'block']}><Img w={'100%'} src={step2image} /></Box>
        </Flex>
      </VStack>
    </Box>
  )
}

const ContentTask = (props) => {
  const { title, point, content, isActive, isLock } = props
  const lock = convertBool(isLock)
  const active = convertBool(isActive)
  const { onOpen, ...rest } = useDisclosure()
  return (
    <div>
      <Flex onClick={lock || !active ? () => { } : onOpen} bg={'#1b1d39'} _hover={{ bgColor: '#101125', cursor: 'pointer' }} borderRadius={'10px'} mb={2} justify={'space-between'} alignItems={'center'} px={5} py={3} gap={3}>
        <Box >
          <Text fontSize={{ base: 'small', lg: 'large' }} fontWeight={600}>{title}</Text>
          <Text fontSize={'x-small'} fontWeight={'600'}> {point} point</Text>
        </Box>

        <Flex gap={4} width={'fit-content'} px={3}>
          {active ? <Badge colorScheme='green'>Active</Badge> : <Badge colorScheme='orange'>Inactive</Badge>}
          {lock && <Badge colorScheme='red'>Locked</Badge>}
        </Flex>
      </Flex>
      <CustomModal {...rest}>
        <ContentModal {...props} post={content} onClose={rest.onClose} />
      </CustomModal>
    </div>
  )
}

export default ContentTask

