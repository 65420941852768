import React, { useCallback, useRef, useState } from 'react'
import { useGoogleLogin } from 'react-google-login'
import { useNavigate } from 'react-router-dom'
import { Spinner, Button, useToast, Flex, Input, InputGroup, InputLeftAddon } from "@chakra-ui/react"
import axios from "axios";
import { BsGoogle } from 'react-icons/bs';

export const clientId = '178534616984-kpt8v52h1a24897ggctrqmoan6p7tnqf.apps.googleusercontent.com'
const REDIRECT_URI = 'http://localhost:3000/airdrop-v2/dashboard'
const apiUrl = process.env.REACT_APP_API_URL || "https://vaselabs-client-oq4ks.ondigitalocean.app/api";
// const REDIRECT_URI = 'https://vaselabs.io/airdrop-v2/dashboard';

export const LoginButton = () => {

  const [twitter, setValue] = useState('') // this is the twitter handle

  const toast = useToast()
  const navigate = useNavigate()
  const captchaRef = useRef(null)

  const fireToast = ({ message, title, status }) => {
    toast({
      position: 'top-right',
      variant: 'solid',
      status: status ?? "success",
      title: title,
      description: message
    });
  };

  const onSuccess = useCallback((response) => {
    console.log('response.profileObj', response.profileObj)
    localStorage.setItem('googleProfile', JSON.stringify(response.profileObj))

    let names = response.profileObj.name;
    let email = response.profileObj.email;
    let ref = "";

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const refParam = urlParams.get('ref');
    if (refParam !== null && refParam !== "") {
      // Since refParam is not null or empty, it's safe to assign it to ref.
      ref = refParam;
    }

    // localStorage.setItem('userEmail', email)
    let username = localStorage.getItem('twitterUsername');

    registerUser(email, names, username, ref);
    // manual redirect to dashboard
    navigate('/airdrop-v2/dashboard', { state: { names, email, username, ref } });
  }, []);

  const registerUser = async (email, names, username, referrer) => {
    await axios.post(
      `${apiUrl}/users/airdopV2`,
      { email, names, username, referrer },
      {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      },
    ).then(({ data }) => {
      console.log(data)

      fireToast({ ...data, status: 'success' });
    }).catch(error => {
      console.log(error)
      fireToast({ ...error, status: 'error' });
    })

  }

  const onFailure = useCallback((error) => {
    console.log({ error });
  }, []);

  const { signIn, loaded } = useGoogleLogin({ onSuccess, clientId, onFailure })


  const handleSubmit = () => {
    localStorage.setItem('twitterUsername', twitter)

    signIn()
  }

  return (
    <>
      {loaded ?
        <Flex flexDir={'column'} gap={3}>
          <div >
            <label htmlFor='handle' pb={2}>Twitter username</label>
            <InputGroup>
              <InputLeftAddon>@</InputLeftAddon>
              <Input placeholder='Enter your twitter username (johnDoe)' id='handle' value={twitter} onChange={event => {
                const inputValue = event.target.value;
                const specialChars = /[!@#$%^&*(),.?":{}|<>]/g;
                if (!specialChars.test(inputValue)) {
                  setValue(inputValue);
                } else {
                  alert('Please remove special characters.');
                }
              }} />
            </InputGroup>

          </div>
          <Button disabled={!twitter} colorScheme='red' onClick={handleSubmit}>
            Continue with Google <BsGoogle style={{ marginLeft: '8px' }} fontSize={'1.2rem'} />
          </Button>
        </Flex>
        : <Spinner />}
    </>
  )
}
