import { ModalOverlay, Modal, ModalContent, ModalCloseButton, ModalBody } from "@chakra-ui/react"
import { ReactNode } from "react"

type props = {
  children: ReactNode,
  isOpen: boolean,
  onClose: () => void
}
export default function CustomModal(props: props) {
  const { isOpen, onClose, children } = props
  return (

    <Modal scrollBehavior="inside" isCentered size={'4xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) '
      />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={6}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
