import { useClipboard, useDisclosure, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { apiInstance, endpoints } from "../../endpoint";
import { getSpecificTaskByTypeAndEmail, getTypes, getUserTasks } from "../../endpoint/fetch";


export const useAirdrop = () => {

  const [userObject, setUserObject] = useState({})

  const { onCopy, value, hasCopied } = useClipboard(userObject.url);
  const toast = useToast()

  const { onOpen: onOpenConnectTwitter, ...connectTwitterModalActions } = useDisclosure()
  const { onOpen: onOpenCreateContent, ...createContentModalActions } = useDisclosure()

  const handleCopyLink = () => {
    onCopy()
    toast({ description: 'Copied' })
  }

  const handleTwitterTaskFor125XVT = () => {
    onOpenConnectTwitter()
    // alert('Complete the tweet task below to earn 125 XVT points.')
  }

  const handleJoinPrivateSale = () => {
    window.location.href = 'https://vaselabs.io/launchpad'
  }

  const handleCreateContent = () => {
    onOpenCreateContent()
  }

  const localProfile = localStorage.getItem('googleProfile')
  const profile = JSON.parse(localProfile)

  const fetchData = useCallback(() => {
    apiInstance.post(endpoints.airdropV2, { email: profile.email },
    ).then(({ data }) => {
      setUserObject(data.message);
      localStorage.setItem('userObject', JSON.stringify(data.message))
    }).catch(error => {
      console.log(error)
    })
  }, [])


  const [types, setTypes] = useState([])

  const fetchAndSetTypes = useCallback(async () => {
    const result = await getTypes()
    setTypes(result.filter(item => item.type))
  }, [])

  const liveTasks = useMemo(() => {
    const sessions = []
    if (!types.length) return sessions
    types.map(obj => (obj.type.includes('live') || obj.type.includes('ama')) && getSpecificTaskByTypeAndEmail(obj.type, profile.email).then(res => res.length && sessions.push(res[0])))
    return sessions
  }, [profile.email, types])


  useEffect(() => {
    fetchAndSetTypes()
    fetchData();
    getUserTasks()
  }, []);


  return {
    value,
    hasCopied,
    handleCopyLink,
    handleTwitterTaskFor125XVT,
    handleJoinPrivateSale,
    handleCreateContent,
    connectTwitterModalActions,
    createContentModalActions,
    userObject,
    profile,
    fetchData,
    liveTasks,
    types
  }
}