import { Button } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type VariantType = 'solid' | 'outline';

type ButtonType = {
  color: 'orange' | 'navy' | 'purple' | 'darkblue' | 'skyblue';
  variant: VariantType;
};

const orange = css`
  background-color: #fb8500;
  border: 1px solid #fb8500;
  :hover {
    background-color: rgba(251, 133, 0, 0.4);
  }
`;
const orangeOutlined = css`
  background-color: #fb850000;
  border: 2px solid #fb8500;
  color: #fb8500;
  :hover {
    color: #ffffff;
    background-color: rgba(251, 133, 0, 0.4);
  }
`;

const navy = css`
  background-color: #023047;
  border: 1px solid #023047;
  :hover {
    background-color: rgba(2, 48, 71, 0.4);
  }
`;
const navyOutlined = css`
  background-color: #02304700;
  border: 2px solid #023047;
  color: #ffffff;
  :hover {
    background-color: rgba(2, 48, 71, 0.4);
  }
`;

const purple = css`
  background-color: #c55aff;
  border: 1px solid #c55aff;
  :hover {
    background-color: rgba(197, 90, 255, 0.4);
  }
`;
const purpleOutlined = css`
  background-color: #c55aff00;
  border: 2px solid #c55aff;
  color: #c55aff;
  :hover {
    color: #ffffff;
    background-color: rgba(197, 90, 255, 0.4);
  }
`;

const darkblue = css`
  background-color: #0a1249;
  border: 1px solid #0a1249;
  :hover {
    background-color: rgba(10, 18, 73, 0.4);
  }
`;
const darkblueOutlined = css`
  background-color: #0a124900;
  border: 2px solid #0a1249;
  color: #ffffff;
  :hover {
    background-color: rgba(10, 18, 73, 0.4);
  }
`;

const skyblue = css`
  background-color: #35baff;
  border: 1px solid #35baff;
  color: #ffffff;
  :hover {
    background-color: rgba(53, 186, 255, 0.4);
  }
`;
const skyblueOutlined = css`
  background-color: #35baff00;
  border: 2px solid #35baff;
  color: #35baff;
  :hover {
    color: #ffffff;
    background-color: rgba(53, 186, 255, 0.4);
  }
`;

export const StyledButton = styled(Button)<ButtonType>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 16px;
  border-radius: 8px;
  color: #ffffff;

  ${(props) =>
    props.color === 'orange' &&
    (props.variant === 'outline' ? orangeOutlined : orange)}
  ${(props) =>
    props.color === 'navy' &&
    (props.variant === 'outline' ? navyOutlined : navy)}
  ${(props) =>
    props.color === 'purple' &&
    (props.variant === 'outline' ? purpleOutlined : purple)}
  ${(props) =>
    props.color === 'darkblue' &&
    (props.variant === 'outline' ? darkblueOutlined : darkblue)}
  ${(props) =>
    props.color === 'skyblue' &&
    (props.variant === 'outline' ? skyblueOutlined : skyblue)}

 @media (max-width: 1199px) {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 26px;
    border-width: 1px;
  }
`;
