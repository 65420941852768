import React, { useEffect } from 'react'
import Wrapper from '../components/Wrapper'
import { Box, Heading, Link, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react'
import { laws, prohibitedActivities } from '../terms-of-use'

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <Wrapper>
      <Box maxW={'700px'} mx={'auto'}>
        <Heading pb={4}>Vaselabs Terms of Use</Heading>
        <Text lineHeight={7} pb={5}>
          Please read these terms carefully and keep a copy of them for your reference. Please also note that there may be specific terms or conditions applicable to you as a user in a given jurisdiction, as detailed below.
        </Text>
        <Text lineHeight={7} pb={5}>
          Please refer to our Privacy Policy, available at www., for information about how we collect, use, share, and otherwise process information about you.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>AGREEMENT TO TERMS</Heading>
        <Text lineHeight={7} pb={5}>
          This End User License Agreement and Terms of Service (“Terms”) is a binding contract between you, an individual user or site visitor, whether personally or on behalf of an entity (“user,” “you,” “your”) and the Vaselabs (the “Vaselabs,” “we,” “us” or “our”) concerning use of the Vaselabs’s services (the “Service”), including the <Link color={'blue.300'} textDecor={'underline'} href="www.vaselabs.io">www.vaselabs.io</Link> website as well as any other media form, media channel, or mobile website related, linked, or otherwise connected thereto (collectively, the “Site”). Currently, Vaselabs maintains and operates www.vaselabs.io as a portal for news, information, and updates about the Vaselabs protocol or blockchain and the Vaselabs ecosystem.
        </Text>

        <Text lineHeight={7} pb={5}>
          VASELABS IS A TECHNOLOGICAL PLATFORM ONLY. WE ARE NOT A BROKER, FINANCIAL INSTITUTION, CREDITOR, BORROWER, AGENT, OR PROVIDE ANY FINANCIAL ADVICE. BY ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE, PLEASE DO NOT USE THE SERVICE OR SITE.
        </Text>

        <Text lineHeight={7} pb={5}>
          Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these terms at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of the terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised terms by your continued use of the Service after the date such revised terms are posted.
        </Text>
        <Text lineHeight={7} pb={5}>
          The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
        </Text>
        <Text lineHeight={7} pb={5}>
          Vaselabs is not intended as, and does not provide, any investment or financial advice whatsoever. As with any financial or investment decisions, you should conduct your own research and due diligence investigation, to properly evaluate the benefits and risks of any investment or financial transaction. You should also seek the advice and guidance of qualified accountants, financial advisors, tax advisors, legal counsels and investment advisors, in connection with any investment or financial transaction.
        </Text>

        <Text lineHeight={7} pb={5}>
          The Service is intended for users who are at least 18 years old. You agree that by using the Site and the Service you are at least 18 years of age, or accessing the Service under the supervision of a parent or guardian, and you are legally able to enter into a contract. If you are a parent or legal guardian of a user under the age of 18 (or the age of legal majority), you agree to be fully responsible for the acts or omissions of such user in relation to using this Service. If you use the Service on behalf of another person or entity, (a) all references to “you” throughout the terms will include that person or entity, (b) you represent that you are authorized to accept these Terms on that person’s or entity’s behalf, and (c) in the event you or the person or entity violates these Terms, the person or entity agrees to be responsible to us.
        </Text>

        <Text fontWeight={'semibold'} lineHeight={7} pb={5}>
          PLEASE NOTE: THE “DISPUTE RESOLUTION” SECTION OF THIS TERMS CONTAINS AN ARBITRATION CLAUSE THAT REQUIRES DISPUTES TO BE ARBITRATED ON AN INDIVIDUAL BASIS, AND PROHIBITS CLASS ACTION CLAIMS. IT AFFECTS HOW DISPUTES BETWEEN YOU AND VASELABS ARE RESOLVED. BY ACCEPTING THESE TERMS, YOU AGREE TO BE BOUND BY THIS ARBITRATION PROVISION. PLEASE READ IT CAREFULLY.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>SCOPE OF LICENSE TO USERS</Heading>

        <Text lineHeight={7} pb={5}>
          The Service is licensed, not sold, to you for use only under the terms of the service, subject to your complete and ongoing compliance with the terms and conditions of the terms. Vaselabs hereby grants you a personal, limited, revocable, non-transferable license to access and use the Service solely for your use.
        </Text>
        <Text lineHeight={7} pb={5}>
          You may not modify, alter, reproduce, or distribute the Service. You may not directly rent, lease, lend, sell, redistribute, or sublicense the Service. You may not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of any portion of the Service, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law), nor attempt to disable or circumvent any security or other technological measure designed to protect the Service or any content available through the Service. If you breach these license restrictions or otherwise exceed the scope of the licenses granted in the terms, then you may be subject to prosecution and damages, as well as liability for infringement of intellectual property rights, and denial of access to the Service.
        </Text>
        <Text fontWeight={'semibold'} lineHeight={7} >
          WITHOUT LIMITING ANY OTHER PROVISION OF THIS terms, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THIS terms OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
        </Text>
        <Text lineHeight={7} pb={5}>
          If we terminate or suspend your access to the Site for any reason, you are prohibited from attempting to access the Site under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your access, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>PROHIBITED ACTIVITIES</Heading>
        <Text lineHeight={7} pb={5}>
          You may not access or use the Service for any purpose other than that for which we make the Service available. The Service may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
        </Text>
        <Text lineHeight={7} pb={5}>
          As a user of the Service, you agree not to:
        </Text>

        <OrderedList spacing={4} pb={5} listStylePos={'inside'}>
          {prohibitedActivities.map(activity => <ListItem key={activity}>{activity}</ListItem>)}
        </OrderedList>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>SITE MANAGEMENT</Heading>
        <Text lineHeight={7} pb={5}>
          We reserve the right, but not the obligation, to: (1) monitor the Site for violations of this terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or this terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (4) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>INFORMATION OWNERSHIP</Heading>
        <Text lineHeight={7} pb={5}>
          Unless otherwise indicated in writing by us, the Services and all content and other materials contained therein, including, without limitation, the Vaselabs logo and all designs, text, graphics, pictures, information, data, software, sound files, and/or other files related thereto and/or associated therewith and the selection and arrangement thereof (collectively, “Content”) are and shall remain the sole and proprietary property of Vaselabs and/or our affiliates or licensors, including the Developers, if and as applicable.
        </Text>
        <Text lineHeight={7} pb={5}>
          If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Services in breach of these Terms, your right to use the Services will terminate immediately and you must, at our option, either return or destroy any Content you have copied, downloaded , or otherwise obtained from the Services. No right, title or interest in or to the Services or any Content thereon is or shall be transferred to you, and all rights not expressly granted herein are reserved by Vaselabs and/or its affiliates. Any use of the Services not expressly permitted by these Terms shall be deemed a material breach of these Terms and may violate copyright, trademark and/or other laws.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>TRADEMARKS</Heading>
        <Text lineHeight={7} pb={5}>
          “Vaselabs” and our logos, our product or service names, our slogans and the look and feel of the Service are trademarks of the Vaselabs and may not be copied, imitated or used, in whole or in part, without our prior written permission, which may be obtained by emailing operations@Vaselabs.io. All other trademarks, registered trademarks, product names, and company names or logos mentioned on the Service are the property of their respective owners. Reference to any products, services, processes, or other information by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by us.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>GOVERNING LAW AND DISPUTE RESOLUTION</Heading>
        <Text lineHeight={7} pb={5} fontWeight={'bold'}>
          **Please read the following section carefully because it requires you to arbitrate certain disputes and claims with Vaselabs and limits how you can seek relief from us unless you opt out of arbitration by following the instructions set forth below. No class or representative actions or arbitrations are allowed under this arbitration provision. **In addition, arbitration precludes you from suing in court or having a jury trial. **
        </Text>
        <Text lineHeight={7} pb={5}>
          This Agreement shall be governed by and construed following the laws of the country of formation.
        </Text>
        <Text lineHeight={7} pb={5}>
          Any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by a single arbitrator as decided by Vaselabs.
        </Text>
        <Text lineHeight={7} pb={5}>
          The decision of the arbitrator shall be binding on the Parties, the language to be used in the arbitral proceedings shall be English.
        </Text>
        <OrderedList spacing={4} pb={5} listStylePos={'inside'}>
          {laws.map(law => <ListItem dangerouslySetInnerHTML={{ __html: law }} key={law} />)}
        </OrderedList>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>CORRECTIONS</Heading>
        <Text lineHeight={7} pb={5}>
          There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice. Vaselabs does not warrant that the content will be uninterrupted error-free or free of computer viruses, contaminants, or other harmful items.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>DISCLAIMER</Heading>
        <Text lineHeight={7} pb={5} fontWeight={'bold'}>
          THE SITE AND SERVICE ARE PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, VASELABS WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE SITE OR SERVICE, INCLUDING, BUT NOT LIMITED TO INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL OR CONSEQUENTIAL DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT YOUR USE OF THE SITE AND SERVICE WILL BE AT YOUR SOLE RISK. VASELABS IS NOT RESPONSIBLE FOR ANY DAMAGES OR LOSSES THAT RESULT FROM YOUR USE OF THE SERVICE, INCLUDING, BUT NOT LIMITED TO, YOUR USE OR INABILITY TO USE THE SERVICE; ANY CHANGES TO OR INACCESSIBILITY OR TERMINATION OF THE SERVICE; ANY DELAY, FAILURE, UNAUTHORIZED ACCESS TO, OR ALTERATION OF ANY TRANSMISSION OR DATA; ANY TRANSACTION OR AGREEMENT ENTERED INTO THROUGH THE SERVICE; ANY ACTIVITIES OR COMMUNICATIONS OF THIRD PARTIES; OR ANY DATA OR MATERIAL FROM A THIRD PERSON ACCESSED ON OR THROUGH THE SERVICES. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE OR SERVICE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE.
        </Text>
        <Text lineHeight={7} pb={5} fontWeight={'bold'}>
          IF YOU ARE DISSATISFIED WITH THE SERVICE, YOU AGREE THAT YOUR SOLE AND EXCLUSIVE REMEDY SHALL BE FOR YOU TO DISCONTINUE YOUR USE OF THE SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
        </Text>
        <Text lineHeight={7} pb={5} >
          Vaselabs may link to products and services offered by third parties through the Service. These third-party products and services are not offered by Vaselabs is not responsible for any damages or losses that you might incur as a result of your use or purchase of these products and services.
        </Text>
        <Text lineHeight={7} pb={5} >
          You shall and hereby do waive California Civil Code Section 1542 or any other similar law of any jurisdiction, which says in substance: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor.” Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. You may have other rights which vary from jurisdiction to jurisdiction.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>INDEMNIFICATION</Heading>
        <Text lineHeight={7} pb={5} >
          You hereby agree to defend, indemnify, and hold Vaselabs harmless from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of (1) use of the Service; (2) breach of this terms; (3) any breach of your representations and warranties outlined in this terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; (5) any overt harmful act toward any other user of the Service with whom you connected via the Service; or (6) any breach of, or failure to comply with applicable law. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding that is subject to this indemnification upon becoming aware of it.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>MODIFYING AND TERMINATING OUR SERVICE</Heading>
        <Text lineHeight={7} pb={5} >
          We reserve the right to modify our Service or to suspend or stop providing all or portions of our Service at any time. You also have the right to stop using our Service at any time. We are not responsible for any loss or harm related to your inability to access or use our Service.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>USER DATA</Heading>
        <Text lineHeight={7} pb={5} >
          We will maintain certain data that you transmit to the Site to manage the performance of the Site, as well as data relating to your use of the Site. Although we perform routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</Heading>
        <Text lineHeight={7} pb={5} >
          You agree and consent to receive disclosures and communications from us regarding our services (“Communications”), including, but not limited to
        </Text>

        <UnorderedList spacing={4} pb={5}>
          <ListItem>Terms and conditions of service, and amendments thereto;</ListItem>
          <ListItem>Privacy policies and notices, and amendments thereto;</ListItem>
          <ListItem>Client agreements and receipts;</ListItem>
          <ListItem>Legal and regulatory disclosures and communications; and</ListItem>
          <ListItem>Customer service communications.</ListItem>
        </UnorderedList>

        <Text lineHeight={7} pb={5} >
          We may provide Communications to you by email or by making them accessible on the Site or through email (including via “hyperlinks” provided online and in emails). In our sole discretion, we may also provide you with any Communication via paper.
        </Text>

        <Text lineHeight={7} pb={5} >
          Visiting the Site, sending us emails, and completing online forms constitute Communication. You consent to receive Communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email, and on the Site, satisfy any legal requirement that such communication be in writing.
        </Text>
        <Text lineHeight={7} fontWeight={'bold'} >
          YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.
        </Text>
        <Text lineHeight={7} pb={5}>
          You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction that require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>WITHDRAWAL OF CONSENT</Heading>
        <Text lineHeight={7} pb={5} >
          You may withdraw your consent to receive Communications under these terms by contacting us at <a href="mailto:support@vaselabs.io" style={{ color: 'skyblue', textDecoration: 'underline' }}>support@vaselabs.io</a>. We will process your request to withdraw your consent to receive electronic Communications in a reasonable time. After we process your request, your access and use of the Service will terminate.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>TERMINATION AND CHANGES</Heading>
        <Text lineHeight={7} pb={5} >
          We reserve the right, in our sole discretion, to discontinue the provision of your Communications, or to terminate or change the terms and conditions on which we provide Communications. We will provide you with notice of any such termination or change as required by law.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>MISCELLANEOUS</Heading>
        <Text lineHeight={7} pb={5} >
          These terms and any policies or operating rules posted by us on the Site or concerning the Service constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these terms shall not operate as a waiver of such right or provision. This term operates to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these terms or use of the Service. You agree that these terms will not be construed against us by having drafted them. You hereby waive any, and all defenses you may have based on the electronic form of these terms and the lack of signing by the parties hereto to execute these terms.
        </Text>

        <Heading size='sm' fontWeight={'bold'} pb={3} color={'purple.300'}>CONTACT US</Heading>
        <Text lineHeight={7} pb={5} >
          To resolve a complaint regarding the Site or to receive further information regarding the use of the Service, please contact us at: <a href="mailto:support@vaselabs.io" style={{ color: 'skyblue', textDecoration: 'underline' }}>support@vaselabs.io</a>.
        </Text>

      </Box>
    </Wrapper>
  )
}

export default TermsOfUse