import * as React from "react"
import { SVGProps } from "react"

const ChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={37}
    height={37}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.792 20.042 18.5 27.75l7.708-7.708"
      stroke="#FB8500"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10.792 9.25 7.708 7.708 7.708-7.708"
      stroke="#8ECAE6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChevronDown
