import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import Home from "./Page/Home";
import { customTheme } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom";
import Gallery from "./Page/gallery";
import Layout from "./components/Layout";
import ReactGA from "react-ga";
import React, { useEffect, useRef } from "react";
import About from "./Page/About";
import BuyGuide from "./Page/BuyGuide";
import PrivateSales from "./Page/PrivateSales";
import { MoralisProvider } from "react-moralis";
import { NotificationProvider } from "web3uikit";
import MigrationEnd from "./components/MigrationEnded";
import MigrationState from "./components/migrationState";
import Airdrop from "./Page/airdrop";
import Excel from "./Page/zkyms";
import AirdropEnd from "./components/airdropEnded";
import AirdropComingSoon from "./components/airdropComingSoon";
import VerticallyCenter from "./components/joinWaitListPopup";
import AirdropV2Index from "./Page/airdropV2/airdropIndex";
import AirdropV2 from "./Page/airdropV2";
import LaunchPad from "./Page/launchPad";
import { gapi } from "gapi-script";
import { clientId } from "./Page/airdropV2/signInButton";
import PreSaleWhitelistForm from "./Page/airdropV2/presaleWhitelistForm";
import PreSale from "./Page/presale";
import PrivacyPolicy from "./Page/privacyPolicy";
import TermsOfUse from "./Page/termsOfUse";

const APP_ID = process.env.REACT_PUBLIC_VASE_APP_ID;
const SERVER_URL = process.env.REACT_PUBLIC_VASE_SERVER_URL;

const GA_ID = "UA-222576032-1";
ReactGA.initialize(GA_ID);

const usePageView = () => {
  const location = useLocation();
  React.useEffect(() => {
    // @ts-ignore
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(GA_ID);
      // @ts-ignore
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
};

function App() {
  usePageView();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const timerRef = useRef<() => NodeJS.Timeout | null>(() =>
    setTimeout(() => {
      onOpen();
    }, 2000),
  );

  const handleClose = () => {
    onClose();
    // @ts-ignore
    timerRef.current = null;
  };

  const isPrivateSalesPage =
    location.pathname.includes("launchpad") ||
    location.pathname.includes("presale") ||
    location.pathname.includes("airdrop-v2");

  useEffect(() => {
    function start() {
      gapi.client.init({ clientId: clientId, scope: "" });
    }

    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    timerRef.current();
    // @ts-ignore
    return () => clearTimeout(timerRef.current);
  }, []);
  return (
    <ChakraProvider theme={customTheme}>
      <MoralisProvider initializeOnMount={false}>
        <NotificationProvider>
          <Layout>
            {/* {!isPrivateSalesPage && < VerticallyCenter isOpen={isOpen} onClose={handleClose} timerRef={timerRef} />} */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="products" element={<About />} />
              <Route path="buy_guide" element={<BuyGuide />} />
              {/* <Route path="migrate" element={<MigrationState />} /> */}
              <Route path="private_sales" element={<PrivateSales />} />
              <Route path="airdrop" element={<AirdropComingSoon />} />
              <Route path="presale" element={<PreSale />} />

              <Route path="launchpad" element={<LaunchPad />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-of-use" element={<TermsOfUse />} />
              <Route path="airdrop-v2" element={<AirdropV2 />} />
              <Route path="airdrop-v2/dashboard" element={<AirdropV2Index />} />
              <Route path="/rmnt" element={<Excel />}></Route>
            </Routes>
          </Layout>
        </NotificationProvider>
      </MoralisProvider>
    </ChakraProvider>
  );
}

export default App;
