import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, Flex, Text, Skeleton, Badge } from '@chakra-ui/react'
import { baseColor } from './color'
import { useCallback, useMemo, useState } from 'react'
import { getSpecificTaskByTypeAndEmail } from '../../endpoint/fetch'
import PostTask from './tasktypes/post'
import ContentTask from './tasktypes/content'
import { apiInstance, endpoints } from '../../endpoint'
import { convertBool } from '../../utils'


const DbTaskAccordion = ({ title }) => {
  const [tasks, setTasks] = useState([])
  const [isLoading, setLoading] = useState(false)

  const fetchTasks = useCallback(async () => {
    const userObj = localStorage.getItem('userObject')
    const { email } = JSON.parse(userObj)

    setLoading(true)
    getSpecificTaskByTypeAndEmail(title, email).then(result => {
      setTasks(result)
    }).finally(() => setLoading(false))
  }, [title])

  const renderTasks = useMemo(() => {
    let renders = null
    if (isLoading) return renders = <Skeleton w={'100%'} h={'50px'} rounded={6} />
    if (!tasks || !tasks.length || !title) return renders = <Flex justify={'center'} py={4}>No tasks</Flex>
    switch (title.toLowerCase()) {
      case 'post':
        renders = tasks.map(task => <PostTask key={task.id} {...task} />)
        break;
      case 'repost':
        renders = tasks.map(task => <Task key={task.id} {...task} />)
        break;

      case 'comment':
        renders = tasks.map(task => <Task key={task.id} {...task} />)
        break;
      case 'like':
        renders = tasks.map(task => <Task key={task.id} {...task} />)
        break;

      default:
        renders = tasks.map(task => <ContentTask key={task.id} {...task} />)
        break;
    }
    return renders
  }, [isLoading, tasks, title])


  return (
    <Accordion allowToggle w={'100%'} >
      <AccordionItem bg={baseColor} borderRadius={'15px'} border={'none'}>
        <h2>
          <AccordionButton px={5} py={5} onClick={fetchTasks}>
            <Box fontWeight={600} textTransform={'capitalize'} as="span" flex='1' textAlign='left'>
              {title ?? 'Complete Task'}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          {renderTasks}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default DbTaskAccordion


const Task = (props) => {
  const { title, point, link, type, id: task_id, isLock, isActive } = props

  const lock = convertBool(isLock)
  const active = convertBool(isActive)

  const onClickTask = () => {
    const userObj = localStorage.getItem('userObject')
    const { email, id } = JSON.parse(userObj)
    apiInstance.post(endpoints.extraTasks,
      { email, point, link, id, task_id, col: type }
    )
  }

  return (
    <Flex as={'a'} href={lock || !active ? null : link} target={lock || !active ? '' : '_blank'} onClick={lock || !active ? () => { } : onClickTask} bg={'#1b1d39'} _hover={{ bgColor: '#101125', cursor: 'pointer' }} borderRadius={'10px'} mb={2} justify={'space-between'} alignItems={'center'} px={5} py={3} gap={3}>
      <Box >
        <Text fontSize={{ base: 'small', lg: 'large' }} fontWeight={600}>{title}</Text>
        <Text fontSize={'x-small'} fontWeight={'600'}> {point} point</Text>
      </Box>
      <Flex gap={4} width={'fit-content'} px={3}>
        {active ? <Badge colorScheme='green'>Active</Badge> : <Badge colorScheme='orange'>Inactive</Badge>}
        {lock && <Badge colorScheme='red'>Locked</Badge>}
      </Flex>
    </Flex>
  )
}
