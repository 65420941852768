import { Box, Flex, Input, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { StyledButton } from "../styledButton";
import { StyleText } from "../Typography";
import Wrapper from "../Wrapper";


type CreateUserResponse = {
    message: string;
    title:string;
};

const NewLetter = () => {
    const [emailValue, setEmailValue] = useState('')
    const toast = useToast()

    const apiUrl =
  process.env.REACT_APP_API_URL ||
  "https://vaselabs-client-oq4ks.ondigitalocean.app/api";

    
    const submitEmail = async () => {
        if (!emailValue) return;
        await axios.post<CreateUserResponse>(
           `${apiUrl}/users/subscribe`,
            { email: emailValue },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        ).then(({ data }) => {
            toast({
                position: 'top-right',
                variant: 'solid',
                status: "success",
                title: data.title,
                description: data.message
            });
            console.log(data);
        }).catch(error => {
            toast({
                position: 'top-right',
                variant: 'solid',
                status: 'error',
                title: "Unsuccessful",
                description: error.message
            })
           
        })

         
            // fetch(`${apiUrl}/users/subscribe`, {
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //     Accept: "application/json",
            //   },
            //   body: JSON.stringify({
            //     "email": emailValue
            //   }),
            // })
            //   .then((res) => res.json())
            //   .then((data) => {
            //     // console.log(data);
            //   });
          

    }
    return <Wrapper>
        <Box
            mx='auto'
            borderRadius={'20px'}
            p='1rem'
            bgColor='#D9D9D9'
        >
            <Flex
                alignItems={'center'}
                justify='center'
                rowGap={'8px'}
                flexDir={{ base: 'column', lg: 'row' }}
            >
                <StyleText color='#023047' texttype='h2' pr={{ lg: '30px' }}>Newsletter</StyleText>
                <Input
                    type='email'
                    textAlign={{ base: 'center', lg: 'left' }}
                    placeholder='Enter your email address'
                    id="email"
                    borderColor='#023047'
                    color='#023047'
                    _placeholder={{ color: 'rgba(2, 48, 71, 0.5)' }}
                    maxW='486px'
                    value={emailValue}
                    onChange={(e) => setEmailValue(e.target.value)}
                />

                <StyledButton
                    ml={{ lg: '8px' }}
                    onClick={submitEmail}
                    color='navy'
                    variant='solid'>
                    Subscribe
                </StyledButton>
            </Flex>
        </Box>
    </Wrapper>
};

export default NewLetter;
