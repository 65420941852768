import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react'
import { baseColor } from './color'

const tiers = [
  { tier: 5, qualification: 100, point: 20 },
  { tier: 4, qualification: 80, point: 17 },
  { tier: 3, qualification: 50, point: 13 },
  { tier: 2, qualification: 25, point: 10 },
  { tier: 1, qualification: 10, point: 7 },
]

const LearnMore = () => {
  return (
    <Accordion allowToggle  >
      <AccordionItem border={'none'}>
        <h2>
          <AccordionButton borderRadius={'10px'}>
            <Box as="span" flex='1' textAlign='left' >
              <Text color={'blue.500'}>
                Learn more...
              </Text>
              <Text fontSize={'small'}> Earn more XVT per referral as your tier increases.</Text>
            </Box>
          </AccordionButton>
        </h2>
        <AccordionPanel mt={5} pb={4} py={6} bgColor={baseColor}>
          <TableContainer >
            <Table colorScheme='twitter'>
              <Thead>
                <Tr>
                  <Th>Tier</Th>
                  <Th>Qualification</Th>
                  <Th>No. of points for each referral</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tiers.map(item => <Tr key={item.tier}>
                  <Td>{item.tier}</Td>
                  <Td>{item.qualification}</Td>
                  <Td>{item.point} XVT</Td>
                </Tr>)}
              </Tbody>
            </Table>
          </TableContainer>
        </AccordionPanel>
      </AccordionItem>


    </Accordion>
  )
}

export default LearnMore