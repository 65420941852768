import { AirdropAccordionProps } from "./accordion";

import axios from "axios";

const apiUrl =
  process.env.REACT_APP_API_URL ||
  "https://vaselabs-client-oq4ks.ondigitalocean.app/api";

export const task1: AirdropAccordionProps = {
  description: 'Complete these tasks and earn XVT points.',
  isAllTaskComplete: false,
  title: 'Complete tasks',
  tasks: [
    {
      isComplete: true,
      onClickTask: () => {

        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;

        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'vase_twit', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'https://twitter.com/VaseLabs'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();


      },
      points: 5,
      task: 'Follow on X'
    },
    {
      isComplete: true,
      onClickTask: () => {
        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;

        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'vase_token_twit', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'https://twitter.com/Vsetoken'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();
      },
      points: 5,
      task: 'Follow Vase token twitter'
    },
    {
      isComplete: false,
      onClickTask: () => {
        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;

        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'vase_tg', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'https://t.me/+TXW78t9fnZwxZjQ0'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();
      },
      points: 5,
      task: 'Join telegram group'
    },
    {
      isComplete: false,
      onClickTask: () => {
        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;

        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'vase_tg_channel', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'https://t.me/+ogfPJKbUUhliN2Q0'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();
      },
      points: 5,
      task: 'Join telegram channel'
    },
    {
      isComplete: false,
      onClickTask: () => {
        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;

        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'vase_link', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'https://www.linkedin.com/company/realvaselabs/'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();
      },
      points: 5,
      task: 'Follow on LinkedIn'
    },
    {
      isComplete: false,
      onClickTask: () => {
        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;

        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'vase_youtube', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'http://www.youtube.com/@realvaselab'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();
      },
      points: 5,
      task: 'Subscribe to Youtube'
    },
    {
      isComplete: false,
      onClickTask: () => {
        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;

        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'vase_medium', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'https://medium.com/@realvaselabs'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();
      },
      points: 5,
      task: 'Follow on Medium'
    },
    {
      isComplete: false,
      onClickTask: () => {
        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;

        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'vase_discord', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'https://discord.com/invite/up3JWvVkkK'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();
      },
      points: 5,
      task: 'Join Discord Server'
    },
    {
      isComplete: false,
      onClickTask: () => {

        const localObj = localStorage.getItem('userObject')
        if (!localObj) return;
        const userObj = JSON.parse(localObj)
        const total = userObj.total;
        const id = userObj.id;


        const verifyTask = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/followTasks`,
              { col: 'retweet', point: 5, total: total, id: id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            );

            console.log(response.data);
            window.location.href = 'https://x.com/VaseLabs/status/1773681517845496072?s=20'

          } catch (error) {
            console.error(error);
          }
        };
        verifyTask();
      },
      points: 5,
      task: 'Repost tweet & tag 3 friends'
    },
  ]
}

