import {
  Flex,
  Link,
  Image,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
} from "@chakra-ui/react";
import vaseFavicon from "../assets/logovector.svg";
import { FaBars } from "react-icons/fa";
import { NavLink, Link as RouterLink } from "react-router-dom";
import Wrapper from "./Wrapper";
import { StyleText } from "./Typography";
// @ts-ignore
// import vaselabWhitePaper from "https://vaselabs.gitbook.io/vaselabs/";

const MobileMenu = () => {

  return (
    <Menu>
      <MenuButton
        bg="transparent"
        as={IconButton}
        aria-label="Options"
        icon={<FaBars />}
        variant="outline"
      />
      <MenuList bg="#444444d3">
        {/* <MenuItem
          as='a'
          target='_blank'
          href='#'
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          Earn Vase Token
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to="/products"
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          products
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to="/gallery"
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          NFT Gallery
        </MenuItem> */}
        {/* <MenuItem
          as={RouterLink}
          to="/migrate"
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          Migrate to V2
        </MenuItem> */}
        <MenuItem
          as={NavLink}
          // href='https://forms.gle/mRXFsyeSbTqGdjLn8'
          // target='_blank'
          to={'/launchpad'}
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          Join Pre-sale
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to="/private_sales"
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          Private sales
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to="/airdrop-v2"
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          Airdrop
        </MenuItem>
        <MenuItem
          as={'a'}
          target='_blank'
          href="https://medium.com/@realvaselabs"
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          Blog
        </MenuItem>
        <MenuItem
          as={'a'}
          target='_blank'
          href="https://bolster.club/#form"
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}>
          Join wait list
        </MenuItem>

        {/* <MenuItem
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}
          as="a"
          target="_blank"
          href="https://pancakeswap.finance/swap?outputCurrency=0x737ed8c950b7F8e35ef23c85Caf449569663d9e6">
          Buy
        </MenuItem> */}
        {/* <MenuItem
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}
          as="a"
          href="/#Community">
          Community
        </MenuItem> */}
        <MenuItem
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}
          as="a"
          href="/#road-map">
          Road map
        </MenuItem>
        <MenuItem
          sx={{
            ":hover, :active, :focus": {
              bg: "#222222d3",
            },
          }}
          as="a"
          target={'_blank'}
          href="https://vaselabs.gitbook.io/vaselabs/">
          White paper
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const Nav = () => {
  return (
    <Wrapper p="1rem" animateOnce>
      <Box
        color={"#fafafa"} p='0'>
        <Box>
          <Flex
            py="8px"
            alignItems={{ base: "center", md: "center" }}
            sx={{
              "a:focus": {
                boxShadow: "none",
              },
            }}
            justify="space-between">
            <Link
              _hover={{ textDecor: "none" }}
              _active={{
                textDecor: "none",
              }}
              as={RouterLink}
              to="/"
              fontSize="16px"
              textAlign="left"
              display="flex"
              alignItems="center"
              columnGap="12px">
              <Flex alignItems='center' columnGap={'8px'}>
                <Image src={vaseFavicon} maxW='45px' maxH='56.39px' />
                <StyleText texttype="bodyBold">Vaselabs</StyleText>
              </Flex>
            </Link>
            <Box display={{ base: "inline-block", lg: "none" }}>
              <MobileMenu />
            </Box>
            <Flex
              display={{ base: "none", lg: "inline-flex" }}
              flexDirection={{ base: "column", md: "row" }}
              gridColumnGap="2rem">
              {/* <Link
              as='a'
              target='_blank'
              href="#"
              borderRadius='30px'
              px='1rem'
              py='.5rem'
              h='100%'
              _hover={{ bg: 'purple.500' }}>
              Earn Vase Token
            </Link>
            <Link py='.5rem' as={RouterLink} to="/products">
              Products
            </Link>
            <Link py='.5rem' as={RouterLink} to="/gallery">
              NFT Gallery
            </Link> */}
              {/* <Link py='.5rem' as={RouterLink} to="/migrate">
                Migrate to V2
              </Link> */}
              <Link py='.5rem'
                as={RouterLink}
                // href='https://forms.gle/mRXFsyeSbTqGdjLn8' 
                // target='_blank'
                to={'/launchpad'}
              >
                Join Pre-sale
              </Link>
              <Link py='.5rem' as={RouterLink} to="/private_sales">
                Private sales
              </Link>
              <Link py='.5rem' as={RouterLink} to="/airdrop-v2">
                Airdrop
              </Link>
              <Link py='.5rem'
                href='https://medium.com/@realvaselabs'
                target='_blank'
              >
                Blog
              </Link>
              <Link py='.5rem'
                href='https://bolster.club/#form'
                target='_blank'
              >
                Join wait list
              </Link>
              {/* <Link
                target="_blank"
                py='.5rem'
                color={'#FB8500'}
                href="https://pancakeswap.finance/swap?outputCurrency=0x737ed8c950b7F8e35ef23c85Caf449569663d9e6">
                Buy
              </Link> */}
              <Link py='.5rem' href="/#road-map" >Road map </Link>
              <Link py='.5rem' target={'_blank'} href="https://vaselabs.gitbook.io/vaselabs/">
                White paper
              </Link>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Nav;
