import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import usdtIcon from "./usdt.png";
import vaseIcon from "./vaseIcon.png";
import { useState, useEffect } from "react";
import HowToBuy from "./howToBuy";
import Affiliate from "./affiliate";
import Distribution from "./distribution";
import { ConnectButton, useNotification } from "web3uikit";
import {
  useMoralis,
  useWeb3Contract,
  useChain,
  useMoralisWeb3Api,
} from "react-moralis";
import { ethers, utils } from "ethers";
import { randomBytes } from "crypto";
import { Contract } from "@ethersproject/contracts";
import axios from "axios";

import TokenSaleContractABI from "./abi.json";
import Countdown from "../../components/countDown";

// if (window.ethereum) {

// } else {
//   alert('Please install web3 wallet first')

// }

const pinkSale = `https://www.pinksale.finance/launchpad/0x8A71108e9D415d0e70dE4Cac3dE5E5235A0191a3?chain=BSC`;

const LaunchPad = () => {
  const [isBNB, setIsBNB] = useState(false);

  const vaseTokenAddress = "0x27c77063a7131969863c4902e6C6b12ec2Ab7a93";
  const usdtAddress = "0x55d398326f99059fF775485246999027B3197955";
  const bnbAddress = "0x0000000000000000000000000000000000000000";
  const saleAddress = "0x81c78c422756303a5DE2f211eD08b7B70A1697E7";
  const chainIdLocal = "0x38";

  const [buyTokenValue, setBuyTokenValue] = useState("0");
  const Web3Api = useMoralisWeb3Api();

  const { chainId, chain, switchNetwork } = useChain();
  const [vaseBoughtFromProtocol, setVaseProtocolBought] = useState("0");
  const [runningVaseBalProtocol, setRunningVaseBalProtocol] = useState("0");
  const [usdtBalFromProtocol, setBusdProtocolBal] = useState("0");
  const [usdtWalletBalValue, setUsdtdWalletBal] = useState("0");
  const [bnbWalletBalValue, setBnbdWalletBal] = useState("0");
  const [salesStatus, setSalesStatus] = useState("0");
  const [widCount, setWidCount] = useState("0");
  const [monthlyP, setmonthlyP] = useState("0");
  const [firstPay, setFirstPay] = useState("0");
  const [buyers, setBuyers] = useState("0");
  const [usdtReferral, setUsdtReferral] = useState("0");
  const [bnbReferral, setBnbReferral] = useState("0");
  const [userData, setUserData] = useState([]);
  const [soldVase, setAllVase] = useState("0");
  const [refCode, setRefCode] = useState("");
  const [genCode, setGenCode] = useState("");
  const [contractRefCode, setContractRefCode] = useState("");
  const [refCount, setRefCount] = useState(0);

  const [coinAmount, setCoinAmount] = useState("");
  const [vaseAmount, setVaseAmount] = useState("");

  const { account, isWeb3Enabled, Moralis, isInitialized } = useMoralis();

  const { runContractFunction } = useWeb3Contract();
  const dispatch = useNotification();

  const wethInterface = new utils.Interface(TokenSaleContractABI.abi);

  const contract = new Contract(saleAddress, wethInterface);

  async function generateUniqueAlphanumeric() {
    return new Promise((resolve, reject) => {
      const charset =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const codeLength = 5;
      let codeGen = "";

      // Generate random bytes
      const randomBytesArray = randomBytes(codeLength);

      for (let i = 0; i < codeLength; i++) {
        // Convert each random byte to a number between 0 and charset.length - 1
        const randomIndex = randomBytesArray[i] % charset.length;
        codeGen += charset.charAt(randomIndex);
      }

      resolve(codeGen);
    });
  }

  // Connect to the provider
  // const provider = new ethers.providers.Web3Provider(window.ethereum);
  // // Instantiate the contract
  // const tokenSaleContract = new ethers.Contract(
  //   saleAddress,
  //   TokenSaleContractABI.abi,
  //   provider.getSigner()
  // );

  // Fetching the balance
  // async function fetchBalance() {
  //   try {
  //     const balance = await provider.getBalance(account);
  //     const balanceInEther = parseFloat(ethers.utils.formatEther(balance)).toFixed(2);

  //     setBnbdWalletBal(balanceInEther);
  //   } catch (error) {
  //     console.error('Error fetching balance:', error);
  //   }
  // }

  //usdt wallet balance;
  const { runContractFunction: getUsdtTokenBalance } = useWeb3Contract({
    abi: [
      {
        constant: true,
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: usdtAddress,
    functionName: "balanceOf",
    params: {
      account: account,
    },
  });

  // get sales status
  const { runContractFunction: getSalesStatus } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "getSalesStatus",
        outputs: [
          {
            internalType: "enum VasePresale.PresaleState",
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "getSalesStatus",
  });

  // total user vase balance
  const { runContractFunction: getBoughtVase } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "getUserVaseBalance",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "getUserVaseBalance",
  });

  // user monthly vase pay
  const { runContractFunction: getUserMonthlyPay } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "getUserMonthlyPay",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "getUserMonthlyPay",
  });

  // user first vase pay
  const { runContractFunction: getUserFirstPay } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "getUserFirstPay",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "getUserFirstPay",
  });

  // get user wid count
  const { runContractFunction: getWidCount } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "getWidCount",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "getWidCount",
  });

  // get all buyers
  const { runContractFunction: getBuyers } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "getAllBuyers",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "getAllBuyers",
  });

  //get users usdt referral amount
  const { runContractFunction: getUsdtReferal } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "getUsdtReferal",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "getUsdtReferal",
  });

  //get users bnb referral amount
  const { runContractFunction: getBnbReferal } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "getBnbReferal",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "getBnbReferal",
  });

  //user all data
  const { runContractFunction: getUserData } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "userData",
        outputs: [
          {
            components: [
              {
                internalType: "uint256",
                name: "monthlyPay",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "firstRelease",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "totalVase",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "runningVaseBalance",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "widCount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "tokenPurchaseAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "usdtReferralAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "bnbReferralAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "refCount",
                type: "uint256",
              },
              {
                internalType: "string",
                name: "refCode",
                type: "string",
              },
              {
                internalType: "string",
                name: "referrer",
                type: "string",
              },
            ],
            internalType: "struct VasePresale.User",
            name: "",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "userData",
  });

  //all sold vase
  const { runContractFunction: allVase } = useWeb3Contract({
    abi: [
      {
        inputs: [],
        name: "allVase",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "allVase",
  });

  let approveOptionsUSDT = {
    abi: [
      {
        constant: false,
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    contractAddress: usdtAddress,
    functionName: "approve",
  };

  let buyOptions = {
    abi: [
      {
        inputs: [
          {
            components: [
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "string",
                name: "referrer",
                type: "string",
              },
              {
                internalType: "string",
                name: "refCode",
                type: "string",
              },
            ],
            internalType: "struct VasePresale.TokenSale",
            name: "tokenSale",
            type: "tuple",
          },
        ],
        name: "enterPresale",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "enterPresale",
  };

  // staking withdraw
  let withdrawOption = {
    abi: [
      {
        inputs: [],
        name: "withdrawMonthlyVesting",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    contractAddress: saleAddress,
    functionName: "withdrawMonthlyVesting",
  };

  async function withdrawFunction() {
    const tx = await runContractFunction({
      params: withdrawOption,
      onError: (error) => {
        console.log(error.data.message);
        dispatch({
          type: "error",
          message: "Vesting Schedule Not Reached",
          title: "withdrawal",
          position: "topL",
        });
      },
      onSuccess: () => {
        console.log("Vesting Claimed");
        dispatch({
          type: "success",
          message: "Vesting Withdrawn",
          title: "withdrawal",
          position: "topL",
        });
      },
    });
  }

  useEffect(() => {
    // get stake history
    if (chainId == chainIdLocal) {
    }
    if (isWeb3Enabled && chainId == chainIdLocal) {
      UpdateStats();
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get("ref") != null) {
      let refCode = urlParams.get("ref");
      setRefCode(refCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isWeb3Enabled, isInitialized]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSwitchNetwork = async () => {
    const provider = window.ethereum;
    const chainId = await provider.request({ method: "eth_chainId" });
    console.log(chainId);
    const binanceChainId = chainIdLocal;

    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: binanceChainId }],
      });
      console.log("You have succefully switched to Binance mainnet ");
      onClose();
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        console.log(
          "This network is not available in your metamask, please add it",
        );
      }
      console.log("Failed to switch to the network");
    }
  };

  async function UpdateStats() {

    //user data
    const userData = (
      await getUserData({ onError: (error) => console.log(error) })
    );

    // setRefCount(userData.refCount.toString());
    setGenCode(userData.refCode.toString());

    //get busd wallet balance
    const usdtBalFromWallet = (
      await getUsdtTokenBalance({ onError: (error) => console.log(error) })
    ).toString();

    const formatedUsdtBalFromWallet = ethers.utils.formatUnits(
      usdtBalFromWallet,
      "ether",
    );
    setUsdtdWalletBal(Math.floor(formatedUsdtBalFromWallet));

    // user vase bought
    const vaseBought = (
      await getBoughtVase({ onError: (error) => console.log(error) })
    ).toString();
    const formatedVaseBt = ethers.utils.formatUnits(vaseBought, "ether");

    setVaseProtocolBought(formatedVaseBt)
    // console.log(`total user vase bought: ${formatedVaseBt}`)

    // user monthly pay
    const monthlyPays = (
      await getUserMonthlyPay({ onError: (error) => console.log(error) })
    ).toString();
    const formatedVaseMonthPay = ethers.utils.formatUnits(monthlyPays, "ether");
    setmonthlyP(formatedVaseMonthPay);
    // console.log(`user monthly vase pay: ${formatedVaseMonthPay}`)

    // user first pay
    const firstPays = (
      await getUserFirstPay({ onError: (error) => console.log(error) })
    ).toString();
    const formatedFirstPay = ethers.utils.formatUnits(firstPays, "ether");
    setFirstPay(formatedFirstPay);
    // console.log(`first user vase pay: ${formatedFirstPay}`)

    // running vase balance
    const widCt = (
      await getWidCount({ onError: (error) => console.log(error) })
    ).toString();
    setWidCount(widCt);

    // if (parseInt(widCount) < 1) {
    //   setVaseProtocolBought(Math.floor(formatedVaseBt));
    // }
    // else {

    //   const vaseBal = ethers.utils.formatUnits(userData.runningVaseBalance.toString(), "ether");
    //   const vaseBl = parseInt(vaseBal);
    //   setVaseProtocolBought(Math.floor(vaseBl));

    // }



    // user usdt referral
    const usdtRef = (
      await getUsdtReferal({ onError: (error) => console.log(error) })
    ).toString();
    const formatedUsdt = ethers.utils.formatUnits(usdtRef, "ether");
    setUsdtReferral(formatedUsdt);

    //bnb referral
    const bnbtRef = (
      await getBnbReferal({ onError: (error) => console.log(error) })
    ).toString();
    const formatedBnb = ethers.utils.formatUnits(bnbtRef, "ether");
    setBnbReferral(formatedBnb);


    //all vase sold
    const vase = (
      await allVase({ onError: (error) => console.log(error) })
    ).toString();
    const formatedVase = ethers.utils.formatUnits(vase, "ether");
    setAllVase(formatedVase);

    //all vase sold
    const allBuyers = (
      await getBuyers({ onError: (error) => console.log(error) })
    ).toString();
    setBuyers(allBuyers);

    //sales status
    const stats = (
      await getSalesStatus({ onError: (error) => console.log(error) })
    ).toString();
    setSalesStatus(stats);

    // fetchBalance();
  }

  const handleCoinAmountChange = (e) => {
    setCoinAmount(e.target.value);

    if (!isBNB) {
      setVaseAmount(Math.trunc((1 * e.target.value) / 0.0834));
    } else {
      setVaseAmount(Math.trunc((600 * e.target.value) / 0.18));
    }
  };

  async function handleBuySubmit(e) {
    e.preventDefault();
    const minUSDT = 10;
    const maxUSDT = 30000;
    const minBNB = 0.18;
    const maxBNB = 100;

    // const minShow = minStaking.toLocaleString('en-US');
    if (genCode == "" || genCode == null) {
      const generatedCode = await generateUniqueAlphanumeric();
      setGenCode(generatedCode);
    }

    if (genCode == "" || genCode == null) {
      dispatch({
        type: "error",
        message: "Please refresh as referral code generation failed",
        title: "presale",
        position: "topR",
      });
      return;
    }

    const amountToApprove = coinAmount;
    if (!isBNB) {
      approveOptionsUSDT.params = {
        spender: saleAddress,
        amount: ethers.utils.parseUnits(amountToApprove, "ether").toString(),
      };
      if (!account) {
        dispatch({
          type: "error",
          message: "No wallet connected",
          title: "presale",
          position: "topR",
        });
        return;
      }

      if (parseInt(amountToApprove) > parseInt(usdtWalletBalValue)) {
        dispatch({
          type: "error",
          message: "Insufficient usdt balance",
          title: "presale sale",
          position: "topR",
        });
        return;
      }

      if (parseInt(amountToApprove) < minUSDT) {
        dispatch({
          type: "error",
          message: "Minimum sale is 10 usdt",
          title: "presale",
          position: "topR",
        });
        return;
      }
      if (parseInt(amountToApprove) > maxUSDT) {
        dispatch({
          type: "error",
          message: "Maximum sale is 30000 usdt",
          title: "presale",
          position: "topR",
        });
        return;
      }

      const txn = await runContractFunction({
        params: approveOptionsUSDT,
        onError: (error) => {
          console.log(error);
          dispatch({
            type: "Error",
            message: error,
            title: "Approval Error",
            position: "topR",
          });
        },
        onSuccess: () => {
          handleApproveSuccess(approveOptionsUSDT.params.amount);
        },
      });
      await txn.wait(1);
    } else {
      // if (parseInt(amountToApprove * 100) > parseInt(bnbWalletBalValue * 100)) {
      //   dispatch({
      //     type: "error",
      //     message: "Insufficient bnb balance",
      //     title: "presale sale",
      //     position: "topR",
      //   })
      //   return;
      // }

      // if (parseInt(amountToApprove * 100) < parseInt(minBNB * 100)) {
      //   dispatch({
      //     type: "error",
      //     message: "Minimum sale is 0.18 bnb",
      //     title: "presale",
      //     position: "topR",
      //   })
      //   return;
      // }
      // if (parseInt(amountToApprove * 100) > parseInt(maxBNB * 100)) {
      //   dispatch({
      //     type: "error",
      //     message: "Maximum sale is 100 bnb",
      //     title: "presale",
      //     position: "topR",
      //   })
      //   return;
      // }

      // try {
      //   // Request access to user's accounts
      //   await window.ethereum.request({ method: 'eth_requestAccounts' });

      //   // Construct the method call data
      const tokenSaleData = {
        token: bnbAddress,
        amount: ethers.utils.parseUnits(amountToApprove, "ether").toString(),
        referrer: refCode,
        refCode: genCode,
      };

      //   // Call the contract method
      //   const transaction = await tokenSaleContract.enterPresale(tokenSaleData, {
      //     value: tokenSaleData.amount // Sending BNB value
      //   });

      //   // Wait for transaction confirmation
      //   await transaction.wait();

      //   console.log('entered successfully!');
      //   dispatch({
      //     type: "success",
      //     message: "transaction successful",
      //     title: "presale",
      //     position: "topR",
      //   })
      // } catch (error) {
      //   console.error('Error entering presale:', error);
      //   dispatch({
      //     type: "error",
      //     message: error.data.message,
      //     title: "presale",
      //     position: "topR"
      //   })
      // }

      // const vaseBought = (
      //   await getBoughtVase({ onError: (error) => console.log(error) })
      // ).toString();
      // const formatedVaseBt = ethers.utils.formatUnits(vaseBought, "ether")

      // setVaseProtocolBought(formatedVaseBt)
    }
  }

  async function handleApproveSuccess(amountToBuyFormated) {
    buyOptions.params = {
      tokenSale: {
        token: usdtAddress,
        amount: amountToBuyFormated,
        referrer: refCode,
        refCode: genCode,
      },
    };

    const tx = await runContractFunction({
      params: buyOptions,
      msgValue: amountToBuyFormated,
      onError: (error) => {
        console.log(error);
        dispatch({
          type: "error",
          message: error.data.message,
          title: "presale",
          position: "topR",
        });
      },
      onSuccess: () => {
        dispatch({
          type: "success",
          message: "transaction successful",
          title: "presale",
          position: "topR",
        });

        const apiUrl =
          process.env.REACT_APP_API_URL ||
          "https://vaselabs-client-oq4ks.ondigitalocean.app/api";

        const localObj = localStorage.getItem("userObject");
        if (!localObj) return;
        const userObj = JSON.parse(localObj);
        const total = userObj.total;
        const id = userObj.id;

        const verifySale = async () => {
          try {
            const response = await axios.post(
              `${apiUrl}/users/extraTasks`,
              { col: "vase_presale", point: 500, total: total, id: id },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              },
            );
            console.log(response);
          } catch (error) {
            console.error(`error: ${error}`);
          }
        };
        verifySale();
      },
    });
    // await tx.wait(1);
    const vaseBought = (
      await getBoughtVase({ onError: (error) => console.log(error) })
    ).toString();
    const formatedVaseBt = ethers.utils.formatUnits(vaseBought, "ether");

    setVaseProtocolBought(formatedVaseBt);
  }

  // useEffect(() => {
  //   if (chainIdLocal !== chainId) onOpen()
  // }, [])

  return (
    <Box maxW={"1200px"} w={"100%"} mx={"auto"}>
      {/**  */}
      <Box minH={"3rem"} p={4} mb={4}>
        <Alert
          status="info"
          my={4}
          rounded={4}
          sx={{ justify: "start" }}
          alignItems="start"
        >
          <AlertIcon />
          <Flex flexDir="column" gap={2}>
            <Text fontWeight={"bold"}>Recommended</Text>
            <UnorderedList>
              <ListItem>Use Trustwallet for Mobile phone.</ListItem>
              <ListItem>Use Metamask for PC.</ListItem>
            </UnorderedList>
          </Flex>
        </Alert>
      </Box>

      <Flex justify={"end"} pb={6} px={4} flexWrap={"wrap-reverse"}>
        <Flex gap={3}>
          <ConnectButton moralisAuth={true}></ConnectButton>
          {/* <ConnectWalletButton moralisAuth={true} /> */}

          {chainIdLocal !== chainId ? (
            // <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={'lg'} isCentered>
            //   <ModalOverlay />
            //   <ModalContent>
            //     <ModalHeader textAlign='center'>Switch Network</ModalHeader>
            //     <ModalBody pb={6} textAlign='center'>
            //       <Text py={3}>Click the button below to switch to
            //         <Text as="span" px={1} fontWeight='bold' color={'yellow.600'}>BNB</Text>
            //         Smart Chain</Text>
            <Button
              colorScheme="twitter"
              size="lg"
              variant="outline"
              onClick={handleSwitchNetwork}
            >
              Switch to BNB
            </Button>
          ) : (
            //     </ModalBody>
            //   </ModalContent>
            // </Modal>
            <div></div>
          )}
        </Flex>
      </Flex>
      <Flex flexDir={{ base: "column", lg: "row" }} gap={4}>
        {/* left  */}
        <Box
          p={4}
          bg={"#0F1226"}
          flexGrow={1}
          w={"100%"}
          maxW={{ base: "100%", lg: "379px" }}
          rounded={4}
        >
          {/* timer */}
          <Flex
            p={2}
            bgColor={"#1F2235"}
            rounded={4}
            justify={"space-between"}
            alignItems={"center"}
            textAlign={"center"}
          >
            {/* <Countdown flexDir='row' date="2024-02-29T00:00:00" /> */}
            <Text fontWeight={"bold"}>
              Total bought: {parseInt(vaseBoughtFromProtocol)} VSE
            </Text>

            <Text fontWeight={"bold"}>
              Monthly Vesting: {parseInt(monthlyP)} VSE
            </Text>
            {/*<Box>
              <Heading size={'md'}>0</Heading>
              <Text fontSize={'x-small'} fontWeight={600}>DAY</Text>
            </Box>
            <Box>
              <Heading size={'md'}>0</Heading>
              <Text fontSize={'x-small'} fontWeight={600}>HOUR</Text>
            </Box>
            <Box>
              <Heading size={'md'}>0</Heading>
              <Text fontSize={'x-small'} fontWeight={600}>MINUTE</Text>
            </Box>
            <Box>
              <Heading size={'md'}>0</Heading>
              <Text fontSize={'x-small'} fontWeight={600}>SECOND</Text>
            </Box> */}
          </Flex>

          {/* Progress */}
          <Box p={4} mt={4} bgColor={"#1F2235"} rounded={4}>
            {/* <Progress value={50} size='lg' colorScheme="twitter" rounded={6} /> */}
            <Flex justify={"space-between"}>
              {/* <Text fontSize={'small'} fontWeight={'bold'} pt={2}>$50,000</Text>
              <Text fontSize={'small'} fontWeight={'bold'} pt={2}>$150,000</Text> */}
            </Flex>
          </Box>

          <Flex justify={"space-between"} p={4}>
            {/*
            <Box>
              <Text fontSize={'x-small'} fontWeight={'bold'} pt={2}>Current Price</Text>
              <Text fontSize={'small'} fontWeight={'bold'}>$0.0834</Text>
            </Box>
          */}
            <Box>
              {/* <Text fontSize={'x-small'} fontWeight={'bold'} pt={2}>Current Price</Text>
              <Text fontSize={'small'} fontWeight={'bold'}>$0.0834</Text> */}
            </Box>
          </Flex>

          {/* Dollar to VSE */}
          {/* <Box px={4} color={'blue.600'} fontWeight={'black'}>
            <Text> $0.0834 = 1 VSE</Text>
          </Box> */}

          <Flex justify={"space-between"} alignItems={"center"} pt={4}>
            {/* <Flex gap={1} justify={'center'} alignItems={'center'} px={4} py={2} bgColor={'#1F2235'} rounded={4}>
              <Img src={usdtIcon} w={8} h={8} />
              <Text fontWeight={'bold'}>USDT</Text>
            </Flex> */}

            {/* <Box>
              <Switch size='lg' colorScheme={isBNB ? 'orange' : 'green'} isChecked={isBNB} onChange={() => setIsBNB(prev => !prev)} />
            </Box> */}

            {/* <Flex gap={1} justify={'center'} alignItems={'center'} px={4} py={2} bgColor={'#1F2235'} rounded={4}>
              <Img src={binanceIcon} w={8} h={8} />
              <Text fontWeight={'bold'}>BNB</Text>
            </Flex> */}
          </Flex>

          {/* <Flex rounded={4} px={3} py={1} bgColor={'gray.800'} justify={'space-between'} textAlign={'center'} fontWeight={'bold'} alignItems={'center'} mt={4}>
            <Box>
              <Text>Min</Text>
              <Text as="span" pl={1} color={isBNB ? 'yellow.600' : 'green.600'}>
                {isBNB ? '0.18 BNB' : '$10'}
              </Text>
            </Box>
            <Box >
              <Text>Max</Text>
              <Text as="span" pl={1} color={isBNB ? 'yellow.600' : 'green.600'}>
                {isBNB ? '100 BNB' : '$30K'}
              </Text>
            </Box>

          </Flex> */}

          {/* payment form */}
          <Box as="form" py={4} mt={6} onSubmit={handleBuySubmit}>
            <Stack spacing={4}>
              {/* <Box>
                <Box as="label" htmlFor="coin" fontSize={'smaller'} pb={1} fontWeight={'bold'}> Pay in
                  <Box as="span" pl={1} color={isBNB ? 'yellow.600' : 'green.600'}>
                    {isBNB ? 'BNB' : 'USDT (BEP20)'}
                  </Box>
                </Box>
                <InputGroup>
                  <InputLeftElement pointerEvents='none'
                    color={isBNB ? 'yellow.600' : 'green.600'}
                    fontSize='x-small'
                    fontWeight={600}
                  >{isBNB ? 'BNB' : 'USDT'}</InputLeftElement>
                  <Input id="coin" type='number' placeholder='Enter amount' value={coinAmount} onChange={handleCoinAmountChange} />
                </InputGroup>
              </Box>
              <Box>
                <Box as="label" htmlFor="vse" fontSize={'smaller'} pb={1} fontWeight={'bold'}> Receive in
                  <Box as="span" pl={1} color={'blue.600'}>
                    VSE</Box>
                </Box>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    color='blue.600'
                    fontSize='x-small'
                    fontWeight={600}
                  >
                    VSE
                  </InputLeftElement>
                  <Input placeholder='' value={vaseAmount} readOnly id="vse" type="number" />
                  <InputRightElement>
                    <Img src={vaseIcon} w={5} h={5} />
                  </InputRightElement>
                </InputGroup>
              </Box> */}
              {/* <Button type="submit" colorScheme={'twitter'} variant={'solid'}>BUY VSE</Button> */}
              <Button
                onClick={withdrawFunction}
                colorScheme={"twitter"}
                variant={"solid"}
              >
                Withdraw Vesting
              </Button>
            </Stack>
          </Box>

          {/* Amount purchased */}
          {/* <Flex justify={'space-between'} alignItems={'center'} maxW={'300px'} mx={'auto'}>
            <Text>Your purchase amount:</Text>
            <HStack spacing={1} px={2} py={1} bgColor={'#1F2235'} rounded={8} fontWeight={'bold'}>
              <Text>{vaseBoughtFromProtocol}</Text>
              <Img src={vaseIcon} w={5} h={5} />
            </HStack>
          </Flex> */}

          <Text
            pt={2}
            color={"gray.600"}
            textAlign={"center"}
            fontSize={"smaller"}
          >
            Earn 5% commission on each referral directly to your wallet.
          </Text>
        </Box>

        {/* right  */}
        <Box p={4} bg={"#0F1226"} rounded={4} w={"100%"} maxW={"806px"}>
          <Tabs isFitted>
            <TabList>
              <Tab fontWeight={"bold"}>HOW TO BUY</Tab>
              <Tab fontWeight={"bold"}>AFFILIATE </Tab>
              <Tab fontWeight={"bold"}>ALLOCATION & DISTRIBUTION</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <HowToBuy />
              </TabPanel>
              <TabPanel>
                <Affiliate />
              </TabPanel>
              <TabPanel>
                <Distribution />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Box>
  );
};

export default LaunchPad;
