import axios from "axios"

const apiUrl = process.env.REACT_APP_API_URL || "https://vaselabs-client-oq4ks.ondigitalocean.app/api";

export const endpoints = {
  addTask: '/users/addTasks',
  getTask: '/users/getTasks',
  getActive: '/users/getActiveTasks',
  addType: '/users/addTasksTypes',
  getTypes: '/users/getTypes',
  getUsers: '/users/getUsers',
  getUserTasks: '/users/getUsersTasks',
  getUserTasksByType: '/users/getTasksType',
  airdropV2: '/users/airdropV2Data',
  extraTasks: '/users/extraTasks',
  verifyPost: '/users/verifyPost',
  getSpecificTask: '/users/getTaskSpecific',
  updateTotal: '/users/updateTotal'
}

export const apiInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})


