import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import { StyleText } from "../Typography";

const HowToBuySteps = ({
  step,
  index,
}: {
  step: { title: string; text: string | JSX.Element };
  index: number | string;
}) => {
  return (
    <Flex
      bg="#1a1839"
      maxW={'690px'}
      margin="1rem auto"
      p={{ base: '16px', lg: '32px' }}
      color={'white'}
      borderRadius="20px"
      alignItems="flex-start"
      flexDirection={{ base: "column", md: "row" }}
      rowGap="2rem"
      columnGap="2rem">
      <Box
        w={{ md: "40px", base: "30px" }}
        h={{ md: "40px", base: "30px" }}
        borderRadius="50%"
        bg="#339bdc;"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <StyleText as={'span'} texttype='bodyBold'>{index}</StyleText>
      </Box>
      <Box>
        <StyleText texttype="bodyBold">
          {step.title}
        </StyleText>
        {typeof step.text === "object" ? (
          step.text
        ) : (
          <StyleText texttype="bodyText" pt={'1rem'}>
            {step.text}
          </StyleText>
        )}
      </Box>
    </Flex>
  );
};

export default HowToBuySteps;
