import { Box, Flex, } from '@chakra-ui/react'
import Products from '../components/home/Products'
import Hero from '../components/home/Hero'
import RoadMap from '../components/home/RoadMap'
import Midle from '../components/home/Midle'
import VaseRaffle from '../components/home/VaseRaffle'
import Ecosystem from '../components/home/ecosystem'
import VaseNFT from '../components/home/vaseNft'
import Bolster from '../components/home/Bolster'
import { Link } from 'react-router-dom'
import Wrapper from '../components/Wrapper'
import { StyledButton } from '../components/styledButton'
import NewLetter from '../components/home/newLetter'
import { useEffect, useRef } from 'react'
import { Tokenomics } from '../components/home/Tokenomics'
import Video from '../components/home/video'
import Utility from '../components/home/utility'
import Partnership from '../components/home/Partnership'


const Marquee = () => (
  <Box bgColor='#0000033' maxW={'1200px'} mx='auto' mb={{ base: '30px', lg: '40px' }}>
    <div
      id="coinmarketcap-widget-marquee"
      // @ts-ignore
      coins="1,1027,825,1839,18674"
      currency="USD"
      theme="dark"
      transparent="true"
      show-symbol-logo="true"></div>
  </Box>
)

// const HowToBuyButton = () => (
//   <Wrapper>
//     <Flex justify={'center'}>
//       <StyledButton color={'orange'} variant='solid' as={Link} to='buy_guide'>
//         How to Buy Vase Token from Pancakeswap
//       </StyledButton>
//     </Flex>
//   </Wrapper>
// )

const Home = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <>
      <Box>
        <Hero />
        <Marquee />
        <Ecosystem />
        <Video />
        <Products />
        <Midle />
        <VaseRaffle />
        <VaseNFT />
        <Bolster />
        <Tokenomics />
        <Utility />
        <RoadMap />
        <Partnership />
        {/* <HowToBuyButton /> */}
        <NewLetter />
      </Box>
    </>
  )
}

export default Home
