import { Box, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"

const Distribution = () => {
  return (
    <Box py={6}>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {/* <Tr>
              <Td fontWeight={'bold'}>Phase 1 price</Td>
              <Td fontWeight={600}>$0.087</Td>
            </Tr> */}
            <Tr>
              <Td fontWeight={'bold'}>Price</Td>
              <Td fontWeight={600}>$0.0834</Td>
            </Tr>
            <Tr>
              <Td fontWeight={'bold'}>Total pre-sale supply</Td>
              <Td fontWeight={600}>9,000,000 $VSE</Td>
            </Tr>
            <Tr>
              <Td fontWeight={'bold'}>Release at TGE</Td>
              <Td fontWeight={600}>15%</Td>
            </Tr>
            <Tr>
              <Td fontWeight={'bold'}>Vesting</Td>
              <Td fontWeight={600}>6 months (14.166% monthly)</Td>
            </Tr>
            <Tr>
              <Td fontWeight={'bold'}>Contract Address</Td>
              <Td fontWeight={600}>
                <Link href='https://bscscan.com/address/0x81c78c422756303a5de2f211ed08b7b70a1697e7'>0x27c77063a7131969863c4902e6C6b12ec2Ab7a93</Link>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Distribution