import { Box, Flex, HStack, Heading, Img, Input, Text, VStack, Stack, Alert, AlertIcon, Skeleton, } from "@chakra-ui/react"
import AirdropAccordion from "./accordion"
import { task1 } from "./tasks"
import { useAirdrop } from "./useAirdrop"
import LearnMore from "./learnMore"
import { TaskCard } from "./twitterTasks"
import joinPresale from './assets/joinPresale.png'
import banner from './assets/banner.png'
import twitterPng from './assets/twitter1.png'
import CustomModal from "./customModal"
import ConnectTwitter from "./connectTwitter"
import CreateContent from "./createContent"
import SignOut from "./signOut"
import { baseColor } from "./color"
import DbTaskAccordion from "./dbTask"
import LiveSessionTask from "./livesessionTask"

const AirdropV2Index = () => {
  const {
    handleCopyLink,
    hasCopied,
    value,
    handleCreateContent,
    handleJoinPrivateSale,
    handleTwitterTaskFor125XVT,
    connectTwitterModalActions,
    createContentModalActions,
    userObject,
    profile,
    fetchData,
    liveTasks,
    types
  } = useAirdrop()


  return (
    <Wrapper>
      {/* airdrop banner */}
      <Box pb={4}>
        <Img src={banner} w={'100%'} />
      </Box>

      {liveTasks.length ? liveTasks.map(task =>
        <LiveSessionTask key={task.id} task={task} refreshUserData={fetchData} />
      ) : <span></span>

      }

      {/* engagements */}
      <Flex pt={6} justify={'space-between'} alignItems={'center'}>
        <Flex w={['70px', '90px']} h={['70px', '90px']}
          bg={'orange.500'}
          borderRadius={'50%'}
          justify={'center'}
          alignItems={'center'}
          flexDir={'column'}
          p={3}
        >
          <Heading size={'md'}>{userObject.total}</Heading>
          <Text fontSize={['small', 'medium']}>XVT</Text>
        </Flex>
        {/*  <HStack
          p={2}
        >
          <Text fontWeight={'500'}>{userObject.all}</Text>
          <Text fontSize={['small', 'medium']}>Participants</Text>
        </HStack>
      */}

        <Flex justify={'end'}>
          <SignOut profile={profile} userObj={userObject} />
        </Flex>
      </Flex>

      {/* Tasks */}
      <VStack spacing={4} pt={6} w={'100%'}>
        <AirdropAccordion {...task1} />
      </VStack>

      {/* generate link */}
      <Box pt={10}>
        {/* <Heading size={'md'}>Generate link</Heading> */}
        {/* <Text pb={4} fontSize={'small'}>Click the button to generate your referral link</Text> */}
        <Stack spacing={2} justify='space-between' direction={{ base: 'column-reverse', lg: 'row' }}>
          {/* <Button color='white' colorScheme={'blue'} bgColor={blue} onClick={handleGenerateLink}>Generate Referral link</Button> */}
          <HStack>
            <Flex alignItems='center' gap={1} justify={'center'} bgColor={'#CD3BD0'} borderRadius='8px' px={4} p={2} >
              <Text fontWeight='600'>Tier</Text>
              <Text>{userObject.tier}</Text>
            </Flex>
            <Flex alignItems='center' justify={'center'} gap={3} bgColor={'#D9D9D922'} borderRadius='8px' p={2} w={'fit-content'}>
              <Text fontWeight='600'>{userObject.refCount}</Text>
              <Text>Referral(s)</Text>
            </Flex>
          </HStack>

        </Stack>

        {/* Generated link output */}
        <Box maxW={'500px'} w={'100%'} py={4}>
          <Input cursor={'pointer'} readOnly value={userObject.url} onClick={handleCopyLink} bgColor={hasCopied ? 'blue.100' : 'unset'} />
        </Box>

        {/* Learn more */}
        <LearnMore />
      </Box>

      <Box pt={6}>
        <Flex flexDir={'column'} gap={2} justify={'start'} rounded={6}>
          <Flex flexDir={'column'} justify={'start'} rounded={6} bgColor={baseColor}>
            <TaskCard
              image={joinPresale}
              task='Join Pre-sale'
              point={500}
              text='Buy $VSE from Pre-sale launchpad, earn 500 points.'
              handleTask={handleJoinPrivateSale}
            />
            <Box minH={'3rem'} px={4} >
              <Alert status='warning' my={4} rounded={4} sx={{ justify: 'start' }} alignItems='start' >
                <AlertIcon />
                <Text fontWeight={'bold'}>Buy Presale using metamask on PC to get XVT.</Text>
              </Alert>
            </Box>
          </Flex>
          {/* Twitter Tasks 125XVT */}
          <TaskCard
            image={twitterPng}
            point={100}
            task='Complete the tweet task below to earn XVT points.'
            isComplete
          />
        </Flex>

        <VStack spacing={4} pt={6} w={'100%'}>

          {types && types.length ? types.map(type => (!type.type.toLowerCase().includes('ama') && !type.type.toLowerCase().includes('live')) &&
            <DbTaskAccordion key={type.id} title={type.type} />
          ) : <Flex flexDir={'column'} gap={3}>
            <Skeleton w={'100%'} h={'50px'} />
            <Skeleton w={'100%'} h={'50px'} />
            <Skeleton w={'100%'} h={'50px'} />
            <Skeleton w={'100%'} h={'50px'} />
          </Flex>}

        </VStack>
      </Box>
      {/* Connect Twitter account */}
      <CustomModal {...connectTwitterModalActions}>
        <ConnectTwitter />
      </CustomModal>

      {/* Create content */}
      <CustomModal {...createContentModalActions}>
        <CreateContent />
      </CustomModal>
    </Wrapper>
  )
}

export default AirdropV2Index

const Wrapper = ({ children }) => {
  return (
    <Box maxW={'800px'} mx={'auto'} px={6} pb={30}>
      {children}
    </Box>
  )
}