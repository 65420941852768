import { Box, Flex, Image } from "@chakra-ui/react";
import { StyleText } from "../Typography";
import Wrapper from "../Wrapper";
import bolsterImage from '../../assets/bolsterImage.png'
import { StyledButton } from "../styledButton";
import { Link } from "react-router-dom";

const Bolster = () => {
    return (
        <Wrapper bgColor="rgba(66, 255, 187, 0.2)">
            <Flex w={'100%'} alignItems={'center'} justify='space-between' rowGap={'2rem'} flexDirection={{ base: 'column', lg: 'row' }} >
                <Box w={'100%'}>
                    <StyleText texttype="h2" pb="1rem">
                        Join Bolster earn over $1,000 worth of Vase Token.
                    </StyleText>
                    <StyleText texttype="bodyText" pb="1rem">
                        Bolster is for everyone looking to leverage the possibilities of the blockchain to their advantage. Providing a measurable reward system that encourages long-term participation, thereby increasing adoption and adding value to the users’ portfolio income.
                    </StyleText>
                    <StyleText texttype="bodyBold">
                        Earn Staking Reward | Earn Affiliate Rewards
                    </StyleText>
                    <StyledButton mt='.5rem' as={'a'} href='https://www.bolster.club' target="_blank" variant={'solid'} borderRadius='8px' color='orange'>
                        Join Bolster waitlist
                    </StyledButton>
                </Box>
                <Box w={'100%'}>
                    <Image
                        src={bolsterImage}
                        maxW={{ md: "530px", base: "325px" }}
                        maxH={{ md: "370px", base: "325px" }}
                        w="100%"
                        h="100%"
                    />
                </Box>
            </Flex>
        </Wrapper>
    );
};

export default Bolster;
