import { Button, Flex, Box, Heading, Popover, PopoverBody, Text, PopoverContent, PopoverTrigger, VStack, Spinner, Avatar } from '@chakra-ui/react'
import { FiUser } from 'react-icons/fi'
import { useGoogleLogout } from 'react-google-login'
import { clientId } from './signInButton'
import { useNavigate } from 'react-router-dom'

const SignOut = ({ profile, userObj }) => {
  const navigate = useNavigate()
  const onLogoutSuccess = () => { navigate('/airdrop-v2') }
  const { signOut, loaded } = useGoogleLogout({ clientId, onLogoutSuccess })

  return (
    <Popover>
      <PopoverTrigger>
        {
          profile !== null ?
            <Avatar name={profile.name} src={profile.imageUrl} /> :
            <Flex
              as={Button}
              h={{ base: '40px', lg: '60px' }}
              w={{ base: '40px', lg: '60px' }}
              justify={'center'}
              alignItems={'center'}
              bgColor={'#1b1d39'}
              variant='ghost'
              borderRadius={'50%'}>
              <FiUser />
            </Flex>
        }
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <VStack spacing={5} py={4} w={'100%'}>

            <Flex flexDir='column' justify='center' p={2} borderBottom={'1px gray.500 solid'} gap={2}>
              <Text textAlign='center' color='blue.400' fontWeight="bold">{profile.name}</Text>
              <Text textAlign='center'>{profile.email}</Text>
              <Text textAlign='center'>{userObj.username}</Text>
            </Flex>

            <Box h='2px' w='100%' bgColor='gray.400'></Box>
            <Heading size={'md'}>{userObj.total} XVT</Heading>
            {
              loaded ?
                <Button disabled={!!!loaded} onClick={signOut} bgColor='red' color='white' w={'100%'}>Log out</Button>
                : <Spinner />
            }
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default SignOut