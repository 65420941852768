import { Flex, Heading } from "@chakra-ui/react"
import Countdown from "../components/countDown"
import Wrapper from "../components/Wrapper"
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import styled from '@emotion/styled'

const StyledHeader = styled(Heading)`
  background: -webkit-linear-gradient(158deg, #74EBD5 0%, #4e5ca9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`

const PreSale = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/launchpad')
  }, [navigate])

  return (
    <Wrapper>
      <Flex mx='auto' flexDir='column'>
        <StyledHeader textAlign={'center'} fontSize={{ base: '2.5rem', lg: '5.5rem' }}
        >Coming soon</StyledHeader>
        <Countdown flexDir='column' date="2024-02-12T15:00:00" />
      </Flex>
    </Wrapper>
  )
}

export default PreSale