import React from 'react'
import { Flex, Heading, Image } from "@chakra-ui/react"
import Logo from '../assets/labslogo.png'
import styled from '@emotion/styled'
import { StyleText } from '../components/Typography'


const StyledHeader = styled(Heading)`
  background: -webkit-linear-gradient(158deg, #74EBD5 0%, #4e5ca9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`

const AirdropComingSoon = () => {

  return (
    <Flex >
      <Flex justify={'center'} flexDir='column' textAlign={'center'} maxW={'866px'} m='auto' p={'1rem'} minH={'60svh'}>
        {/* <Image maxW={'100px'} w={'100%'} h='100%' src={Logo} alt='logo' mx={'auto'}></Image> */}
        <StyledHeader fontSize={{ base: '2.5rem', lg: '5.5rem' }}
        >Coming soon</StyledHeader>
        {/* <StyleText pt={'2rem'} texttype='h2' textAlign={'center'} mx={'auto'}>Get read for distribution</StyleText> */}
      </Flex>
    </Flex>
  )
}

export default AirdropComingSoon;