import { Box, Flex, Image } from "@chakra-ui/react";
import { StyleText } from "../Typography";
import Wrapper from "../Wrapper";
import nftImage from '../../assets/NFT1.png'

const VaseNFT = () => {
    return (
        <Wrapper bgColor="rgba(66, 73, 255, 0.2)">
            <Flex alignItems={'flex-start'} columnGap='2.5rem' rowGap={'2rem'} flexDirection={{ base: 'column', lg: 'row' }} >

                <Box>
                    <StyleText texttype="h2" pb="1rem">
                        Vase NFT
                    </StyleText>
                    <StyleText texttype="bodyText" pb='1rem' >
                        Vase NFT has the best and most unique collections ever within the digital NFT art concept. These collections will not only attract value but will have their use case redefining the concept of NFT.
                    </StyleText>
                    <StyleText texttype="bodyText">
                        Vase NFT is a community-driven project that comes with fanciful designs for profile pictures. Most importantly 5% of sales from Vase NFT collections will be donated to charity.
                    </StyleText>

                </Box>
                <Box>
                    <Image
                        src={nftImage}
                        maxW={{ md: "396px", base: "325px" }}
                        maxH={{ md: "364px", base: "325px" }}
                        w="100%"
                        h="100%"
                    />
                </Box>
            </Flex>
        </Wrapper>
    );
};

export default VaseNFT;
