import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'
import { StyleText } from '../components/Typography'
import Wrapper from '../components/Wrapper'

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <Wrapper>
      <Box
        as="section"
        pb="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_project"
        m="0 auto">
        <StyleText texttype='h2' pt={'1rem'} textTransform="uppercase">
          DNS PROTOCOL
        </StyleText>
        <StyleText texttype={'bodyBold'} pt={'1rem'}>
          Definition of DAO:
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          DAO is a decentralized autonomous organization that is been controlled by a set of computer rules which is also known as smart contracts. DAO are decentralized meaning they can only run on the blockchain system.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          DAO removes the presence or importance of a central decision-making body and allows decisions and consensus to be made and reached by its community. It allows people from all works of life (who don’t know each other) to make decisions, take actions, vote and create value for themselves without involving a central decision-making body.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Considering the hype of the NFT ecosystem and how digital art or collectables have been overhyped and sold without real and tangible value.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          The Vase project is of the view that NFT collectables should derive their initial market value by a consensus via a decentralized voting system. Because the real-time value of an asset is determined by demand.
        </StyleText>
        <StyleText texttype={'bodyBold'} fontSize="18" pt={'1rem'}>
          Why DNS PROTOCOL is the best approach to determine the
          market price of NFT collectibles:
        </StyleText>

        <StyleText texttype='bodyText' pt={'12px'}>
          It’s not a hard fact to note that the value of cryptocurrencies is determined by the amount of money or volume been pumped into such currency, of course on the simple principle of demand and supply.This value is derived from the activities of buyers and sellers who are individuals from different places around the world.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Prices of NFT collectables do not meet the principles of demand. DNS PROTOCOL is designed to give NFT collectables value based on overwhelming demand by the popular voting process. With such a voting mechanism, NFT collectables will derive their authentic value and selling of such collectables will be much easier.
        </StyleText>
        <StyleText texttype={'bodyBold'} fontSize="18" pt={'1rem'}>
          Vote to Earn:
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Majority votes that gave valued authenticity (votes that won the majority) to a collectable will share 5% - 10% of the amount such NFT collectable will be sold. With this method those who participated in the voting process and their votes were positive in ensuring the value of such NFT collectable will as well earn, so everybody will love to participate.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          With the DNS PROTOCOL hyped prices of all NFT collectables will derive their real value determined by the crypto community.
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          MIDLE
        </StyleText>
        <StyleText texttype={'bodyBold'} fontSize="18" pt={'1rem'}>
          TRUST FOR TRANSACTIONS
        </StyleText>
        <StyleText texttype={'bodyBold'} fontSize="18" pt={'1rem'}>
          FREELANCER TOOL
        </StyleText>
        <StyleText texttype={'bodyBold'} fontSize="18" pt={'1rem'}>
          CONTRACTORS TOOL
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Have you faced a challenge of trust? When you find someone who can get
          your job or services done from an online platform but finds it difficult to
          trust because of the fear and dissatisfaction of been scammed.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          MIDLE system is a smart contract computer program built on the
          blockchain that enables a trustless exchange of services and value.
          Middle ensures that the exchange of value / service between both parties
          should be transparent and fair. This means, instead of both parties to
          trust each other which is practically impossible in most cases then they
          should trust the MIDLE system for a fair and smooth transition.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          MIDLE system charges its fee using the vase token to maintain and
          continuous upgrade of the system.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          MIDLE system only displays services that are readily funded. This
          ensures or guarantees a viable job finder for freelancers.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          There are more use of the MIDLE system...
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_project"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          VASE WARS
        </StyleText>
        <StyleText texttype={'bodyBold'} fontSize="18" pt={'1rem'}>
          What is a war?
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          According to the Oxford languages dictionary, war is defined to be
          "a state of armed conflict between different countries or different
          groups within a country".
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          How important and beneficial has war ever been?
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          You can imagine or ask yourself, if there would ever be any peculiar
          reasons to justify the act of war, having seen most reasons this act
          has been initiated.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          However, this act has its benefits and Ian Morris, a professor of
          classics and of history said despite its horrors, war has made our
          world less violent. From Roman conquests to World Wars, Morris
          noticed a pattern: War improved the quality of life for both winners
          and losers, gradually making societies safer and richer. First, in
          the literal term it does, but in reality, wars leave an indelible
          mark in the hearts and history of the people in the particular
          place, mostly tragic and the demerits of wars always outweighs its
          merits.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          The same way a vase is a supportive equipment to nurture flowers and
          delicate plants, we serve as that socio economic community existing
          to nurture businesses, enthusiasts and government creatives into
          growing to their full potential. Same purpose of the Vase to plants
          isn't anything different from what it could metaphorically imply in
          the society and our immediate environment to drive home peace, and
          everything profitable.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Consequently, the $Vasetoken is looking forward to an insuperable
          focus to harmonize and totally eradicate circumstances of war or any
          form of violence on the surface of earth, and have all these
          activities in the metaverse if they should be fully utilized.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          $Vase believes that if wars and violent circumstances must occur
          which are totally different from natural forces, they should be
          quickly organized for economic benefits. $Vase introduces an NFT
          market place where various war arsenals could be sold for purchase
          by individuals or countries who are in dispute. Whatever shape or
          form the disputes have, on winning on the metaverse, this could
          totally be regarded as victory. Players stand the chance to win and
          observers wouldn't be left out.{' '}
          <strong>[Slogan: “LET THE WARS BE ON METAVERSE”]</strong>
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          VASE WALLET
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          To facilitate a safe wallet for Vase holder’s and crypto enthusiasts
          to hold and have control of their tokens. Vase wallets comes with
          unique features to give its users better experience and multi
          functionality. The DApp’s, multi user functionality, multi coin
          functionality, staking and much more.
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          VASE E-COMMERCE
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          The Vase project goal is to ensure grass root crypto adoption. How
          to easily archive this is having daily activities (business life,
          e-commerce and finance) carried out on the blockchain using crypto
          currency as a means of transaction. Vase E-commerce platform will
          ensure all business and logistics transactions to be carried out on
          the blockchain and Vase Token as means of payment for good and
          services. Vase E-commerce alone will guarantee the use of Vase Token
          for everyday business activities and most importantly will keep
          attracting volume to the Vase economy.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Vase E-commerce is embedded with unique features which will drive
          economic benefits to cities, nations and countries by meaningfully
          providing employment opportunities.
        </StyleText>
        <StyleText texttype={'bodyBold'} fontSize={'20px'} as="h2">
          Explained:
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Most interestingly, $vase has more focus on how life should be
          easier and less complicated. Vase aims at "supporting" human
          existence and having all its entirety on the blockchain. This is the
          most fascinating aspect of $Vase products.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          The Vase E-commerce marketplace is a virtual marketplace where
          buyers and sellers can transact with a $Vase token. Here, product
          owners have the ability to sell their products on the marketplace,
          buyers can only make purchases via Vase token.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          This is not the first blockchain-based e-commerce marketplace, but
          we are making the system one of the most sophisticated, such that
          speed, human safety, and less traffic congestion would be our
          watchwords.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          The process of delivery over the years has been improved and
          welcomed, but the issues of naturally occurring circumstances like
          accidents and traffic delays have posed to be a limiting factor.
          These limitations can be completely eradicated and deliveries would
          be by the use of drones. Yes, drones.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          A drone is a flying robot that can be remotely controlled or fly
          autonomously using software controlled flight plans in its embedded
          systems, that work in conjunction with onboard sensors and a global
          positioning system (GPS). UAVs were most often associated with the
          military.
        </StyleText>
        <StyleText texttype={'bodyBold'} fontSize={'20px'} as="h2">
          How do drones work?
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Drones have two basic functions: flight mode and navigation. To fly,
          drones must have a power source, such as a battery or fuel. They
          also have rotors, propellers, and a frame. The frame of a drone is
          typically made of a lightweight, composite material to reduce weight
          and increase maneuverability.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Drones require a controller, which lets the operator use remote
          controls to launch, navigate and land the aircraft. Controllers
          communicate with the drone using radio waves, such as Wi-Fi
          (internetofthingsagenda.techtarget.com) With this, delivery from our
          marketplace would be most effective, payment for our services would
          and can be possible with only $Vasetoken.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Consequently, logistics companies wouldn't be left out as we would
          be incorporating every form of this service such that goods in
          larger quantities can be delivered to buyers, this form could be by
          land, air, or sea. Through this means, VASE E-COMMERCE would
          encourage gainful employment opportunities through the service of
          logistics.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          This also would ensure a strong base or support for businesses.
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          VASE GRANT
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          This will empower and finance viable projects that adds value and
          growth to the blockchain ecosystem.
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          VASE NFT MARKETPLACE
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          The marketplace displays all our nft collections. Vase token will
          be used for the purchase of these collections.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          With the fast growing adoption of NFTs over the past months, and the
          continuous acceleration into the NFT space, it is apparent the
          innovation will continue to become the foundation for a lot of
          products. The VASE NFT Marketplace is a next generation type of
          asset-backed non-fungible assets marketplace.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          The VASE NFT Marketplace will feature both the minting and trading
          of digital properties in the form of NFTs. Art lovers and the entire
          entertainment industry will be able to display their creative
          ingenuity and earn from it.
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          The Marketplace will also feature digital properties or NFTs
          representing real world assets. Art curators across the world
          through the VASE Marketplace will be able to source for and secure
          rare art pieces and have them delivered to them if they so desire.
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          VASE WORLD
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          This is the Vase virtual reality world, where individuals can
          purchase lands, develop estates and meet individuals. Vase world
          brings the real world activities on the metaverse.
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          VASE SWAP (DEX)
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Crypto tokens can be swapped or exchanged using the Vase
          Decentralized exchange platform.
        </StyleText>
      </Box>

      <Box
        as="section"
        py="1rem"
        lineHeight={1.7}
        maxW="fit-content"
        id="vase_"
        m="0 auto">
        <StyleText texttype={'bodyBold'} textTransform="uppercase">
          VASE FINANCE
        </StyleText>
        <StyleText texttype='bodyText' pt={'12px'}>
          Borrowing and Lending is a major feature of the defi protocol which
          has become a huge success after its introduction. Vase Finance will
          not only borrow and lend crypto tokens / assets but has introduced
          borrowing and lending of NFT’s. This is revolutionary and will
          create interoperability between DEFI and NFT…
        </StyleText>
      </Box>

    </Wrapper>
  )
}

export default About
