import { Box, Container, ScaleFade } from '@chakra-ui/react';
import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";

const Wrapper: React.FC<{
  bgColor?: string,
  mb?: string;
  ml?: string;
  mt?: string;
  mr?: string;
  bgImage?: string,
  p?: string;
  bgGradient?: string;
  border?: string;
  animateOnce?: boolean
}> = ({ children, animateOnce, ...rest }) => {
  const ref = useRef(null);
  const { inViewport, enterCount } = useInViewport(
    ref,
    { rootMargin: "-10px" },
    { disconnectOnLeave: false },
    {}
  );

  return (
    <Box w={'100%'}>
      <ScaleFade initialScale={0.8}
        in={animateOnce ? enterCount > 0 : inViewport}
        transition={{ enter: { type: "spring", stiffness: 100 } }}
      >
        <Container
          ref={ref}
          mx='auto'
          maxW="75rem"
          p={{ base: '1rem', lg: '4.125rem' }}
          borderRadius='20px'
          mb={{ base: '30px', lg: '40px' }}
          bgRepeat="no-repeat"
          bgPos={"bottom center"}
          {...rest}
        >
          {children}
        </Container>
      </ScaleFade>
    </Box>
  )
};

export default Wrapper;
