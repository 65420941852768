import { Box, Flex } from "@chakra-ui/react";
import ChevronDown from "../../assets/chevrons-down";
import { StyledGradientText } from "../styledHeader";
import ProductSvg from "../svg/productSvg";
import { StyleText } from "../Typography";
import Wrapper from "../Wrapper";

const Products = () => {
  return (
    <Wrapper bgColor="rgba(2, 48, 71, 0.1)">
      <Flex
        alignItems={{ base: 'center', lg: "flex-start" }}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        rowGap="20px">
        <Box w={'100%'}>
          <StyleText texttype="h2" pb={'1rem'}>
            Products
          </StyleText>
          <StyleText texttype="bodyText" pb={"2.5rem"} >
            Vase products are classified as solution products, they make up the Vase ecosystem.
            These products are use cases which create high demand for vase token.
          </StyleText>
          <Flex flexDir={{ base: 'column', lg: 'row' }}>
            <StyledGradientText
              texttype="bodyBold"
              fontSize={{ base: '12px !important', lg: '18px !important' }}>
              Some key products of Vase Labs ecosystem.
            </StyledGradientText>
            <ChevronDown />
          </Flex>
        </Box>

        <Box>
          <ProductSvg />
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default Products;
