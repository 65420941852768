import { Box, Heading, Image, Link, ScaleFade, SimpleGrid } from '@chakra-ui/react'
import { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import coinMarketCapLogo from '../../assets/coinmarket.png'
import bscScanLogo from '../../assets/bsc.png'
import coinSniper from '../../assets/coinsniper.png'
import icomarks from '../../assets/icomarks.png'
import poolz from '../../assets/poolz.png'
import dexview from '../../assets/dexview-white.png'
import pinksale from '../../assets/pinksale-white.png'
import solidproof from '../../assets/solidproof.png'
import skale from '../../assets/skale.jpg'
import Wrapper from '../Wrapper';

const Partnership = () => {
  const ref = useRef(null);
  const { enterCount } = useInViewport(
    ref,
    { rootMargin: "-10px" },
    { disconnectOnLeave: false },
    {}
  );

  return (
    <ScaleFade initialScale={0.8}
      in={enterCount > 0}
      transition={{ enter: { type: "spring", stiffness: 100 } }}
    >
      <Wrapper bgColor='blackAlpha.300'>

        <Heading textAlign='center' py={5}>Partnerships</Heading>
        <Box ref={ref} maxW={'1200px'}
          mx='auto'
          borderRadius={'20px'}
          p='1rem'
          pb={{ base: '30px', lg: '40px' }}>
          <SimpleGrid columns={[2, 3, 4, 5, 6]}
            alignItems="center"
            justify={"center"}
            spacing={{ base: "2rem", lg: '4rem' }}>
            {/* coinmarketcap */}
            <Link
              target="_blank"
              href='https://coinmarketcap.com'
            >
              <Image transition={'.15s linear'} _hover={{ transform: 'scale(1.1)' }} src={coinMarketCapLogo} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" />
            </Link>

            {/* bscScan */}
            <Link
              href='https://bscscan.com/token/0x27c77063a7131969863c4902e6C6b12ec2Ab7a93#balances'
              target="_blank" >
              <Image src={bscScanLogo} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" transition={'.23s linear'} _hover={{ transform: 'scale(1.1)' }} />
            </Link>

            {/* coin sniper */}
            <Link href='https://coinsniper.net/coin/31910' target="_blank" >
              <Image src={coinSniper} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" transition={'.23s linear'} _hover={{ transform: 'scale(1.1)' }} />
            </Link>

            {/* Poolz */}
            <Link href='https://www.poolz.finance/boost' target="_blank" >
              <Image src={poolz} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" transition={'.23s linear'} _hover={{ transform: 'scale(1.1)' }} />
            </Link>

            {/* icomarks */}
            <Link href='https://icomarks.ai/ico/vaselabs' target="_blank" >
              <Image src={icomarks} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" transition={'.23s linear'} _hover={{ transform: 'scale(1.1)' }} />
            </Link>

            {/* solidproof */}
            <Link href='https://solidproof.io/' target="_blank" >
              <Image src={solidproof} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" transition={'.23s linear'} _hover={{ transform: 'scale(1.1)' }} />
            </Link>

            {/* Dexview */}

            <Link href='https://www.dexview.com' target="_blank" >
              <Image src={dexview} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" transition={'.23s linear'} _hover={{ transform: 'scale(1.1)' }} />
            </Link>

            {/* pinksale */}

            <Link href='https://www.pinksale.finance' target="_blank" >
              <Image src={pinksale} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" transition={'.23s linear'} _hover={{ transform: 'scale(1.1)' }} />
            </Link>

            {/* Skale */}
            <Link href='https://skale.space' target="_blank" >
              <Image src={skale} mx='auto' maxW={{ base: '100px', lg: "100px" }} w="100%" transition={'.23s linear'} _hover={{ transform: 'scale(1.1)' }} />
            </Link>

          </SimpleGrid>
        </Box>
      </Wrapper>
    </ScaleFade>
  )
}

export default Partnership
