import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Input, Text, Image, useToast, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import styled from "@emotion/styled";
import { keyframes } from '@emotion/react'
import axios from "axios";
import ExcelJS from 'exceljs';



const bounce = keyframes`
  from {
    transform: translate3d(0%,0%,0);
  }

  60% {
    transform: translate3d(100%,100%,0);
  }

  to {
     transform: translate3d(0%,0%,40%);
  }
`

const OverLay = styled(Box)`
    background: rgba(15, 14, 14, 0.22);
    backdrop-filter: blur(13.5px);
    min-height: 100vh;
    width: 100%;
`

const Ball1 = styled(Box)`
   width: 222px;
    height: 222px;
    background: #FB8500;
    opacity: 0.5;
    filter: blur(50px);
    border-radius: 111px;
    position: absolute;
    animation: ${bounce} 60s ease infinite;
`

const Ball2 = styled(Box)`
    width: 786px;
    height: 786px;
    right: 8px;
    background: #023047;
    opacity: 0.5;
    filter: blur(50px);
    border-radius: 393px;
    position: absolute;
    animation: ${bounce} 60s ease-in-out infinite;
    animation-delay: 1.5s;
`

const IFrame = styled('iframe')`
    aspect-ratio: 16/9;
    width: 100%;
    min-width: 350px;
    margin-left: -0.6rem;
    @media (min-width: 1300px) {
        min-width: 620px;
        margin-left: 0;
    }
`

const Excel = () => {

    const apiUrl =
        process.env.REACT_APP_API_URL ||
        "https://vaselabs-client-oq4ks.ondigitalocean.app/api";


    const handleSubmission = async (e) => {

        e.preventDefault();
        await axios.post(
            `${apiUrl}/users/excel`,
            { table: 'vase_airdrop' },

            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },

        ).then(({ data }) => {

            // create a new Excel workbook and worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('My Sheet');

            worksheet.columns = data.column;

            // add the data rows to the worksheet
            data.row.forEach(row => worksheet.addRow(row));

            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'aidrop.xlsx';
                a.click();
            });


        }).catch(error => {
            console.log(error.message);

        })
    }



    const handleSubmission2 = async (e) => {

        e.preventDefault();
        await axios.post(
            `${apiUrl}/users/excelPrivate`,
            { table: 'private_sale' },

            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },

        ).then(({ data }) => {

            // create a new Excel workbook and worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('My Sheet');

            worksheet.columns = data.column;

            // add the data rows to the worksheet
            data.row.forEach(row => worksheet.addRow(row));

            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'private_sale.xlsx';
                a.click();
            });


        }).catch(error => {
            console.log(error.message);

        })
    }


    return <Box pos={'relative'} minH='100vh' bgColor='#04071D' overflow={'hidden'}>
        <Ball1 />
        <Ball2 />
        <OverLay >

            <Box maxW='1200px' mx={'auto'} p='1rem' >




                <Flex
                    flexDir={{ base: 'column', lg: 'row' }}
                    justify={'space-between'}
                    rowGap='2rem'
                    py={{ base: '2rem', lg: '3rem' }}>
                    <Box w={'100%'} maxW='500px'>
                        <form onSubmit={handleSubmission}>
                            <Flex rowGap={'20px'} flexDir='column'>


                                <Button type="submit" bgColor={'#35BAFF'} color='white'>Print AirDrop</Button>
                            </Flex>
                        </form>
                    </Box>


                </Flex>


                <Flex
                    flexDir={{ base: 'column', lg: 'row' }}
                    justify={'space-between'}
                    rowGap='2rem'
                    py={{ base: '2rem', lg: '3rem' }}>
                    <Box w={'100%'} maxW='500px'>
                        <form onSubmit={handleSubmission2}>
                            <Flex rowGap={'20px'} flexDir='column'>


                                <Button type="submit" bgColor={'#35BAFF'} color='white'>Print Private-Sale</Button>
                            </Flex>
                        </form>
                    </Box>


                </Flex>





            </Box>
        </OverLay>

    </Box>
};

export default Excel;
