import { Flex, Link } from '@chakra-ui/react';
import { FaGooglePlay, FaAppStore } from 'react-icons/fa';
import { StyledButton } from '../styledButton';
import { StyleText } from '../Typography';

const buyingSteps = [
  {
    title: 'Download Trust Wallet',
    text: (
      <>
        <StyleText texttype='bodyText'>You can download trustwallet app from</StyleText>
        <Flex p=" 1rem 0rem" columnGap="1rem" flexWrap="wrap" rowGap="1rem">
          <StyledButton color={'navy'} variant='solid' as={Link}
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp">
            <FaGooglePlay />
            <StyleText texttype='bodyText' pl='8px'>Play Store</StyleText>
          </StyledButton>
          <StyledButton color={'darkblue'} variant='solid' as={Link}
            target="_blank"
            href="https://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409">
            <FaAppStore />
            <StyleText texttype='bodyText' pl='8px'>Apple Store</StyleText>
          </StyledButton>
        </Flex>
      </>
    ),
  },
  {
    title: 'Create a New Wallet',
    text: 'Create a new wallet and keep your 12 recovery phrase safe.',
  },
  {
    title: 'Send BSC to your trustwallet',
    text: 'Buy bnb and fund your bsc wallet on trustwallet. You can buy bnb from any platform that supports p2p and crypto merchants or transfer bnb from any of your exchanges to your trustwallet.',
  },
  {
    title: 'Connect your wallet to Pancakewap',
    text: ' Click on DApps and open Pancakeswap.',
  },
  {
    title: 'Swap your BNB for Vase',
    text: 'Click on the swap button at the bottom and ensure that the slippage is at 10%. Select Smart Chain (BNB) at the top and Vasee in the bottom. Then click swap',
  },
  {
    title: 'Become A holder',
    text: 'Hodl Vase Token and watch the value of your token grow',
  },
];

export default buyingSteps;
