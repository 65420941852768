import { Box, Flex, Image, Link } from '@chakra-ui/react'
import { useEffect } from 'react'
import { StyleText } from '../components/Typography'
import Wrapper from '../components/Wrapper'
const imgPlaceholder = 'https://res.cloudinary.com/dmrm6nmmr/image/upload/v1653227851/vase/img_placeholder_suah0s.png'

const NFTCard = () => {

  return (
    <Link
      href=""
      width={{ md: '290px', base: '100%' }}
      _hover={{ cursor: 'pointer' }}>
      <Box
        bg="#031227"
        width={{ md: '290px', base: '100%' }}
        borderRadius="8px"
        overflow="hidden"
        transition=".23s ease-in"
        _hover={{
          boxShadow: '0px 0px 40px #03101b9f',
          transform: 'scale(1.02)',
        }}
        _focus={{
          boxShadow: '0px 0px 40px #03101b9f',
          transform: 'scale(1.02)',
        }}>
        <Box height={{ md: '280px', base: '330px' }} width={'100%'}>
          <Image
            src={imgPlaceholder}
            w="100%"
            h="100%"
            objectFit="cover"
            objectPosition="center"
          />
        </Box>
        <Box p={'1rem'}>
          <StyleText texttype='bodyBold' pb={'8px'}>
            NFT
          </StyleText>
          <StyleText texttype='bodyText' pb={'8px'} fontWeight="bold" fontSize="16px">
            $0
          </StyleText>
          <StyleText texttype='bodyText' fontSize="14px">NFT description</StyleText>
        </Box>
      </Box>
    </Link>
  )
}

const Gallery = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <Wrapper>
      <StyleText
        texttype='h2'
        pb={{ base: '2em', lg: '3rem' }}>
        NFT Gallery
      </StyleText>
      <Flex flexWrap="wrap" columnGap="1rem" rowGap="1rem">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
          <NFTCard key={key} />
        ))}
      </Flex>
    </Wrapper>
  )
}

export default Gallery
