import { Box, useBreakpointValue } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import Wrapper from '../Wrapper'

const IFrame = styled.iframe`
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
`

const Video = () => {
  const color = useBreakpointValue({ base: 'transparent', lg: 'black' })
  return (
    <Wrapper bgColor={color}>
      <Box>
        <IFrame src="https://www.youtube.com/embed/61yi6ztduCE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></IFrame>
      </Box>
    </Wrapper>
  )
}

export default Video