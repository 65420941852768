import { apiInstance, endpoints } from "."

export const getTasks = async () => {
  const { data: tasks } = await apiInstance.get(endpoints.getTask)
  return tasks.message
}

export const getUserTasks = async () => {
  const { data: userTasks } = await apiInstance.get(endpoints.getUserTasks)
  return userTasks.message
}

export const getTypes = async () => {
  const { data: types } = await apiInstance.get(endpoints.getTypes)
  return types.message
}

export const getTaskByType = async (type: string) => {
  const { data: tasks } = await apiInstance.post(endpoints.getUserTasksByType, { type })
  return tasks.message
}

export const getSpecificTaskByTypeAndEmail = async (type: string, email: string) => {
  const { data: tasks } = await apiInstance.post(endpoints.getSpecificTask, { type, email })
  return tasks.message
}