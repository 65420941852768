import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import { Box, Flex, SlideFade } from '@chakra-ui/react'
import Wrapper from "../Wrapper";
import { StyleText } from "../Typography";
import ecosystem_bg from '../../assets/ecosystem_bg.svg'

const TextBox: React.FC<{ bgColor: string; offsetY?: string; offsetX?: string }> = ({ children, bgColor, ...rest }) => {
    const ref = useRef(null);
    const { enterCount } = useInViewport(
        ref,
        { rootMargin: "-30px" },
        { disconnectOnLeave: false },
        {}
    );
    return (
        <SlideFade {...rest} in={enterCount > 0}
            transition={{ enter: { type: "spring", stiffness: 150 } }}
        >
            <Box
                w='100%'
                maxW='435px'
                p={'24px'}
                minH='198px'
                borderRadius={'8px'}
                bgColor={bgColor}
                ref={ref}
            >
                <StyleText texttype="bodyText">
                    {children}
                </StyleText>
            </Box>
        </SlideFade>
    )
}

const Ecosystem = () => {

    return (
        <Wrapper bgImage={ecosystem_bg}>
            <StyleText texttype="h2" pb='1rem' >Ecosystem</StyleText>
            <Flex columnGap={'18px'} flexWrap={{ base: 'wrap' }} rowGap='18px' justify={{ base: 'center', lg: 'flex-start' }} pb="18px">
                <TextBox bgColor="#587C8C" offsetX="-100px">
                    The whole concept of the vase project is centered on creating viable
                    solutions on the blockchain which is designed to enable day to day
                    economic activities via a sophisticated consensus algorithm.
                </TextBox>
                <TextBox bgColor="#8C7758" offsetX="-100px">
                    We believe that solutions created on the blockchain brings about
                    transparency and enables borderless economic viability.
                    Most importantly supporting blockchain worldwide adoption.
                </TextBox>
            </Flex>
            <Flex columnGap={'18px'} flexWrap={{ base: 'wrap' }} rowGap='18px' justify={{ base: 'center', lg: 'flex-end' }}>
                <TextBox bgColor="#59588C" offsetX="100px">
                    VASE(VSE) is the native token of the vase ecosystem.
                </TextBox>
                <TextBox bgColor="#7F8C58" offsetX="100px">
                    Giving real value to NFT's. Introducing Vote to Earn (V2E), Interoperability between DEFI & NFT.
                </TextBox>
            </Flex>
        </Wrapper>
    )

};

export default Ecosystem;
