import { Alert, Avatar, Box, Flex, Text, VStack, UnorderedList, ListItem, AlertIcon } from "@chakra-ui/react"
import styled from "@emotion/styled"

const IFrame = styled('iframe')`
    aspect-ratio: 16/9;
    width: 100%;
    min-width: 200px;
    @media (min-width: 1300px) {
        min-width: 420px;
        margin-left: 0;
    }
`

const HowToBuy = () => {
  return (
    <Box py={6} sx={{ whiteSpace: 'wrap' }}>
      <VStack spacing={4} h={'fit-content'}>
        <Flex p={4}
          bgColor={'#1F2235'}
          alignItems={{ lg: 'center' }}
          flexWrap={{ base: 'wrap', lg: 'unset' }}
          rounded={6}
          flexDir={{ base: 'column', lg: 'row' }}
          gap={4} w={'100%'}>
          <Avatar name='1' size={'sm'} bgColor='#0F1226' />
          <Text fontWeight={'bold'}>CONNECT WALLET(Ensure to switch to BNB)</Text>
        </Flex>
        <Flex p={4}
          bgColor={'#1F2235'}
          alignItems={{ lg: 'center' }}
          flexWrap={{ base: 'wrap', lg: 'unset' }}
          rounded={6}
          flexDir={{ base: 'column', lg: 'row' }}
          gap={4} w={'100%'}>
          <Avatar name='2' size={'sm'} bgColor='#0F1226' />
          <Box>
            {/* <Text fontWeight={'bold'}>SELECT PAYMENT TOKEN</Text> */}
            <Text fontWeight={'semibold'} fontSize={'smaller'}>Enter USDT Amount</Text>
          </Box>
        </Flex>
        <Flex p={4}
          bgColor={'#1F2235'}
          alignItems={{ lg: 'center' }}
          flexWrap={{ base: 'wrap', lg: 'unset' }}
          rounded={6}
          flexDir={{ base: 'column', lg: 'row' }}
          gap={4}

          w={'100%'}>
          <Avatar name='3' size={'sm'} bgColor='#0F1226' />
          <Box px={1}>
            <Text fontWeight={'bold'}>BUY $VSE</Text>
            <Text fontWeight={'semibold'} fontSize={'smaller'}>
              Select buy
              and approve the payment on your wallet
            </Text>
          </Box>
        </Flex>
      </VStack>
      <Box mt={8} >
        <IFrame
          width="560"
          height="315"
          src="https://www.youtube.com/embed/8uzbfEqIhH0?si=zd0lQ5QoQMDbDqAo"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></IFrame>
      </Box>
    </Box>
  )
}

export default HowToBuy