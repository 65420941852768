import { Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const tiny = css`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`

const roadMap = css`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

const mobileTextBold = css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`;

const mobileText = css`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
`;

const bodyBold = css`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
`;

const bodyText = css`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
`;

const h1 = css`
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
`;

const h2 = css`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.02em;
`;

const heading1 = css`
  ${h1};
  @media (max-width: 1199px) {
    ${h2}
  }
`;

const heading2 = css`
  ${h2};
  @media (max-width: 1199px) {
    max-width: 289px;
    ${bodyBold}
  }
`;

const textBold = css`
  ${bodyBold};
  @media (max-width: 1199px) {
    ${mobileTextBold}
    max-width: 289px;
  }
`;

const text = css`
  ${bodyText};
  @media (max-width: 1199px) {
    ${mobileText}
    max-width: 289px;
  }
`;

const roadMapText = css`
  ${bodyText};
  @media (max-width: 1199px) {
    ${roadMap}
    max-width: 289px;
  }
`;

const roadMapQ = css`
  ${h1};
  @media (max-width: 1199px) {
    ${bodyBold}
    max-width: 289px;
  }
`;

type StyleTextType = {
  texttype: 'h1' | 'h2' | 'bodyText' | 'bodyBold' | 'roadMapText' | 'roadMapQ'| 'tiny';
  color?: string;
};

export const StyleText = styled(Text)<StyleTextType>`
  font-family: 'Poppins', sans-serif;
  max-width: 480px;
  color: ${(props) => props.color ?? '#ffffff'};
  ${(props) => props.texttype === 'h1' && heading1}
  ${(props) => props.texttype === 'h2' && heading2}
  ${(props) => props.texttype === 'bodyBold' && textBold}
  ${(props) => props.texttype === 'bodyText' && text}
  ${(props) => props.texttype === 'roadMapText' && roadMapText};
  ${(props) => props.texttype === 'roadMapQ' && roadMapQ};
  ${(props) => props.texttype === 'tiny' && tiny};
  font-size: ${(props) => props.fontSize && props.fontSize}
`;
