export const prohibitedActivities = [
  'Systematically retrieve data or other content from the Service to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.',
  'Make any unauthorized use of the Service, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.',
  'Circumvent, disable, or otherwise interfere with security-related features of the Service, including features that prevent or restrict the use or copying of any content or enforce limitations on the use of the Service and/or the content contained therein.',
  'Engage in unauthorized framing of or linking to the Site.',
  'Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.',
  'Make improper use of our support services or submit false reports of abuse or misconduct.',
  'Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.',
  'Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.',
  'Attempt to impersonate another user or person.',
  'Use any information obtained from the Site in order to harass, abuse, or harm another person.',
  'Use the Service as part of any effort to compete with us or otherwise use the Service and/or the content for any revenue-generating endeavor or commercial enterprise.',
  'Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.',
  'Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Service to you.',
  'Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.',
  'Delete the copyright or other proprietary rights notice from any content.',
  'Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.',
  'Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Service or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Service.',
  'Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).',
  'Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.',
  'Disparage, tarnish, distribute hate speech/explicit content or otherwise harm, in our opinion, us, the Service and/or other users of the Service.',
  'Copy, reproduce, distribute, publicly perform or publicly display all or portions of our Service, except as expressly permitted by us or our licensors.',
  'Modify our Service, remove any proprietary rights notices or markings, or otherwise make any derivative works based upon our Service.',
  'Infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of the Vaselabs or any third party.',
  'Use the Service in a manner inconsistent with any applicable laws or regulations.',
  'Use the Service under a different wallet address, email address, or other identifier if we’ve disabled access for you under a different wallet address, email address, or other identifier unless you have our written permission first',
  'Buy, sell, rent, or lease access to your Digital Wallet (as defined below) to any third party, unless you have our written permission first; or',
  'Use or access the Services through any unauthorized third party application(s) or client(s), or to disclose or share your User Account information such as your email password, private keys or other sensitive information with any other person.'
]

export const laws = [
  'No Representative Actions. You and the Vaselabs agree that any dispute arising out of or related to this terms or the Service is personal to you and the Vaselabs and that any dispute will be resolved solely through individual action, and will not be brought as a class arbitration, class action or any other type of representative proceeding.',
  'Arbitration of Disputes. You and Vaselabs waive your rights to a jury trial and to have any other dispute arising out of or related to this terms and the Service, including claims related to privacy and data security, (collectively, “Disputes”) resolved in court. Instead, for any Dispute that you have against Vaselabs you agree to first contact Vaselabs and attempt to resolve the claim informally by sending a written notice of your claim (“Notice”) to Vaselabs by email at support@vaselabs.io. The Notice must (a) include your name, residence address, email address, and telephone number; (b) describe the nature and basis of the Dispute; and (c) set forth the specific relief sought. Our notice to you will be similar in form to that described above.',
  'The arbitration will allow for the discovery or exchange of non-privileged information relevant to the Dispute. The arbitrator, the Vaselabs, and you will maintain the confidentiality of any arbitration proceedings, judgments, and awards, including information gathered, prepared, and presented for purposes of the arbitration or related to the Dispute(s) therein. The arbitrator will have the authority to make appropriate rulings to safeguard confidentiality unless the law provides to the contrary. The duty of confidentiality does not apply to the extent that disclosure is necessary to prepare for or conduct the arbitration hearing on the merits, in connection with a court application for a preliminary remedy or in connection with a judicial challenge to an arbitration award or its enforcement, or to the extent that disclosure is otherwise required by law or judicial decision.',
  'Any Dispute must be filed within one year after the relevant claim arose; otherwise, the Dispute is permanently barred, which means that you and Vaselabs will not have the right to assert the claim.',
  `You have the right to opt out of binding arbitration within 30 days of the date you first accepted the terms of this Section by emailing an opt-out notice to <a href="mailto:support@vaselabs.io" style="color:skyblue;textDecoration:underline">support@vaselabs.io</a>. To be effective, the opt-out notice must include your full name and address and indicate your intent to opt out of binding arbitration.`,
  'If any portion of this Section is found to be unenforceable or unlawful for any reason, (a) the unenforceable or unlawful provision shall be severed from these Terms; (b) severance of the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of this Section or the parties’ ability to compel arbitration of any remaining claims on an individual basis according to this Section; and (c) to the extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall stay pending the outcome of any individual claims in arbitration. Further, if any part of this Section is found to prohibit an individual claim seeking public injunctive relief, that provision will have no effect to the extent such relief is allowed to be sought out of arbitration, and the remainder of this Section will be enforceable.'
]
