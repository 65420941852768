import React from 'react'
import Wrapper from '../Wrapper'
import { Box, Flex, Image } from '@chakra-ui/react'
import { StyleText } from '../Typography'
import nft from '../../assets/icon/nft.png'
import pay from '../../assets/icon/pay.png'
import sme from '../../assets/icon/sme.png'
import stake from '../../assets/icon/stake.png'
import raffle from '../../assets/icon/raffle.png'
import vase from '../../assets/icon/vase.png'

const utilities = [
  { text: 'Payment for services & products', icon: pay },
  { text: "Valuation for NFT's", icon: nft },
  { text: 'Staking (APY)', icon: stake },
  { text: 'Support for SME\'s', icon: sme },
  { text: 'Raffle', icon: raffle },
]

const Utility = () => {
  return (
    <Wrapper bgColor={'#05071d'} p='1rem' mb='0rem' border='1px solid #10153d'>
      <Box
        bgImage={vase}
        backgroundPosition={'-45% -150px'}
        backgroundSize={'contain'}
        backgroundBlendMode={'lighten'}
        borderRadius='10px'
        backgroundRepeat={'no-repeat'}>

        <Box bgColor={'#05071dd3'}>
          <Flex p={'1rem'} justifyContent={'center'} columnGap={'10%'} py={['3rem', '5rem']} flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
            <Box w={'fit-content'} py={'1rem'} minW={{ base: '25ch', lg: '45ch' }} >
              <StyleText texttype='h2' color={'#f7f7f7'}>Vase Token ($VSE) Utility</StyleText>
            </Box>

            <Flex columnGap={'1.5rem'} rowGap={'1.5rem'} flexWrap={'wrap'} justifyContent={{ base: 'center', lg: 'flex-end' }}>
              {utilities.map((item,) => <Flex
                bgColor={'#580658'}
                p={'1rem'}
                flexDir={'column'}
                rowGap={'10px'}
                w={['100%', '240px']}
                borderRadius={'12px'}
                alignItems={'stretch'}
                justifyContent={'start'}
                key={item.text}>
                <Image src={item.icon} h={'35px'} w={'35px'} color={'black'} />
                <StyleText fontWeight={'600'} color={'#f7f7f7'} texttype='bodyText'>{item.text}</StyleText>
              </Flex>)}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Wrapper>
  )
}


export default Utility