import { Box, Button, Checkbox, Flex, HStack, Img, Text } from '@chakra-ui/react'

import VaseIcon from './assets/icon.png'
import { BsTwitterX } from 'react-icons/bs'
import { baseColor, blue } from './color'

type TaskCardProps = {
  isComplete?: boolean,
  image: string,
  task: string,
  point: number,
  text?: string,
  handleTask?: () => void,
  isAvailable?: boolean
}

export const TaskCard = (props: TaskCardProps) => {
  const { text, image, task, point, handleTask, isComplete = false, isAvailable = true } = props
  return (
    <Flex onClick={isComplete ? () => { } : handleTask} gap={2} cursor='pointer' p={4} bgColor={baseColor} borderRadius={8} alignItems={'center'}>
      <Box w={'120px'}><Img w={'100%'} src={image} /></Box>

      <Flex justifySelf={'stretch'} flexDir={'column'} justify={'space-between'} w={'100%'}>
        <Box>
          <Flex p={1} gap={1} bgColor={'blackAlpha.600'} borderRadius={10} w={'fit-content'}>
            <Img w={'15px'} src={VaseIcon} />
            {/* <Text fontSize={'x-small'} fontWeight={'600'} w={'fit-content'} >{point} XVT</Text> */}
          </Flex>

          <Text fontSize={['medium', 'x-large']} fontWeight={600} maxW='550px'>{task}</Text>
          {text && <Text fontSize={'small'}> {text}</Text>}
        </Box>
        <Box alignSelf={'end'}>

          {/* {
            isAvailable ?
              <Text
                fontSize={['small']}
                px={{ base: 4, lg: 6 }}
                py={{ base: 2, lg: 3 }}
                borderRadius='10px'
                fontWeight={500}
                color={isComplete ? 'white' : 'gray.400'}
                bgColor={isComplete ? blue : '#D9D9D922'}
              >{isComplete ? 'Completed' : 'Incomplete'}</Text>
              :
              <Text
                fontSize={['small']}
                px={{ base: 4, lg: 6 }}
                py={{ base: 2, lg: 3 }}
                borderRadius='10px'
                fontWeight={500}
                color={'white'}
                bgColor={'#9A789F'}
              >Task Unavailable</Text>
          } */}
        </Box>
      </Flex>
    </Flex>
  )
}


type MiniTaskCardProps = {
  isComplete?: boolean,
  task: string,
  point: number,
  handleTask: () => void
}

export const MiniTaskCard = (props: MiniTaskCardProps) => {
  const { handleTask, point, task, isComplete } = props
  return (
    <Flex as={Button} bgColor={baseColor} borderRadius={8} alignItems={'center'} justify={'space-between'}
      py={8}
      onClick={handleTask}
    >
      <HStack spacing={2}>
        <BsTwitterX fontSize={'1.2rem'} />
        <Text fontWeight={600} fontSize={'large'}>{task}</Text>
        <Flex p={1} gap={1} bgColor={'blackAlpha.600'} alignItems='center' borderRadius={10}>
          <Img w={'15px'} src={VaseIcon} />
          <Text fontSize={'x-small'} fontWeight={'600'} w={'fit-content'} >{point} XVT</Text>
        </Flex>
      </HStack>
      <Box>
        {
          isComplete &&
          <Checkbox size='lg' colorScheme={'twitter'} color={blue} isChecked={isComplete} />
        }
      </Box>
    </Flex>
  )
}