import { Box, Flex } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { StyleText } from "./Typography";

const initialState = {
  days: '0',
  hours: '0',
  minutes: '0',
  seconds: '0',
}

const Countdown: React.FC<{ flexDir?: 'row' | 'column', date?: string, caption?: string }> = ({ caption, date = '03/31/2023', flexDir = 'row' }) => {
  const [countdownDate, setCountdownDate] = useState(new Date(date).getTime());
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setInterval(() => setNewTime(), 1000);
  }, []);

  const setNewTime = () => {
    const currentTime = new Date().getTime();
    if (currentTime >= countdownDate) {
      setState(initialState)
      return
    }
    if (countdownDate) {

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24)).toString();
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      ).toString();
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
      ).toString();
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000).toString();;

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      days = `${days}`;
      if (numbersToAddZeroTo.includes(Number(hours))) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(Number(minutes))) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(Number(seconds))) {
        seconds = `0${seconds}`;
      }

      setState({ days: days, hours: hours, minutes, seconds });
    }
  };

  return (
    <Flex flexDir={flexDir} justify={{ base: 'center', lg: 'space-between' }} alignItems={'center'} columnGap='1.5rem' width='100%' flexWrap={{ base: 'wrap', lg: 'nowrap' }}>

      {caption && <StyleText texttype="bodyBold">{caption}</StyleText>}

      <Flex textAlign='center' flexGrow={1} justify={'space-between'} alignItems={'center'} columnGap='.5rem' flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
        <Flex columnGap='.5rem' flexDir={'column'}>
          <StyleText texttype="bodyText">{state.days || '0'}</StyleText>
          <StyleText texttype="bodyText" color={'#ff99aa'}>Days</StyleText>
        </Flex>
        <Flex columnGap='.5rem' flexDir={'column'}>
          <StyleText texttype="bodyText">{state.hours || '00'}</StyleText>
          <StyleText texttype="bodyText" color={'#faf9aa'}>Hours</StyleText>
        </Flex>
        <Flex columnGap='.5rem' flexDir={'column'}>
          <StyleText texttype="bodyText">{state.minutes || '00'}</StyleText>
          <StyleText texttype="bodyText" color={'#2fb9fa'}>Minutes</StyleText>
        </Flex>
        <Flex columnGap='.5rem' flexDir={'column'}>
          <StyleText texttype="bodyText">{state.seconds || '00'}</StyleText>
          <StyleText texttype="bodyText" color={'#afe9ea'}>Seconds</StyleText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Countdown;