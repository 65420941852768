import { Box, Button, Flex, Heading, Input, Text, VStack } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import axios from "axios";
import { apiInstance, endpoints } from "../../endpoint";

const CreateContent = () => {

  const [input, setInput] = useState()
  const [userObject, setUserObj] = useState(null)

  const handleTask = () => {

    const twitterUrl = input;
    const username = userObject.username;
    const total = userObject.total;
    const id = userObject.id;


    // https://x.com/VaseLabs/status/1772203342292226218?s=20

    const twitterRegex = /https?:\/\/x\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/\d+/;
    const regex = /x\.com\/([^\/?]+)/;

    if (twitterRegex.test(twitterUrl)) {

      const match = twitterUrl.match(regex);

      if (match && match[1]) {
        const user = match[1];
        if (user === username) {
          // alert("all good to go");

          const verifyPost = async () => {
            try {
              const response = await apiInstance.post(endpoints.extraTasks, { col: 'vase_post_content', point: 300, total: total, id: id });
              alert(response.data.message);
            } catch (error) {
              console.error(error);
            }
          };
          verifyPost();
        } else {
          alert("username mismatch on twitter(X) link");
        }
      }

    } else {
      alert("Not a valid Twitter(X) post link.");
    }

  }

  useEffect(() => {
    const localObj = localStorage.getItem('userObject')
    if (!localObj) return;
    const userObj = JSON.parse(localObj)
    setUserObj(userObj)
  }, [])

  return (
    <Box p={4}>
      <Heading py={4} size={'lg'}>Create Content</Heading>
      <VStack spacing={6}>
        <Flex gap={4} w={'100%'} justify={'space-between'} alignItems={'center'}>
          <Flex flexDir={'column'} gap={3}>
            <Heading size={'md'}>Step 1</Heading>
            <Text>
              Create a content about Vaselabs and share on Twitter(X).
            </Text>
          </Flex>
        </Flex>
        <Flex gap={4} w={'100%'} justify={'space-between'} alignItems={'center'}>
          <Flex flexDir={'column'} gap={3}>
            <Heading size={'md'}>Step 2</Heading>
            <Text>
              Choose any of these focus: $VSE a 1000x potential token, Vaselabs products.
            </Text>
          </Flex>
        </Flex>

        <Flex flexDir={'column'} gap={2} w={'100%'} justify={'space-between'}>
          <Heading size={'md'}>Step 3</Heading>
          <Text>
            Paste link
          </Text>
          <Input placeholder="Paste link here" value={input} onChange={e => setInput(e.target.value)} />
          <Box>
            <Button disabled={!input} onClick={handleTask} colorScheme="twitter" px={8}>Verify</Button>
          </Box>
        </Flex>
      </VStack>
    </Box>
  )
}

export default CreateContent
