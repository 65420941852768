import { Badge, Box, Button, Flex, Text } from '@chakra-ui/react'
import { apiInstance, endpoints } from '../../endpoint'
import { useState } from 'react'
import { convertBool } from '../../utils'

const LiveSessionTask = ({ task, refreshUserData }) => {
  const { id: task_id, point, link, type, isLock } = task
  const [show, setShow] = useState(true)
  const lock = convertBool(isLock)

  const onClickTask = () => {
    const userObj = localStorage.getItem('userObject')
    const { email, id } = JSON.parse(userObj)
    apiInstance.post(endpoints.extraTasks,
      { email, point, link, id, task_id, col: type }
    ).then(() => {
      setShow(false)
      refreshUserData()
    })
  }

  return (
    <Flex p={4}
      mb={4}
      bgColor={task.type.includes('twitter_live') ? 'blue.600' : 'purple.600'}
      borderRadius={16}
      shadow={'lg'}
      display={show ? 'flex' : 'none'}
      flexWrap={'wrap'}
      gap={4}
      justify={'space-between'}
      alignItems={'center'} >
      <Box>
        <Text fontSize={'xl'} fontWeight={'bold'}>{task.title}</Text>
        {task.content ? <Text fontSize={'sm'} pt={2}>{task.content}</Text> : null}
      </Box>
      <Box>
        <Button disabled={lock} alignItems={'center'} display='inline-flex' onClick={lock ? () => { } : onClickTask} variant='outline'>Claim {task.point} XVT points <Badge ml={4} bgColor='red.500' display={lock ? 'inline-block' : 'none'}>Locked</Badge></Button>
      </Box>
    </Flex>
  )
}

export default LiveSessionTask