import { Box, Flex, Image } from "@chakra-ui/react";
import { StyledButton } from "../styledButton";
import { StyledGradientText } from "../styledHeader";
import { StyleText } from "../Typography";
import Wrapper from "../Wrapper";

const Midle = () => {
  return (
    <Wrapper bgColor="rgba(66, 192, 255, 0.2)" >
      <Flex
        alignItems={'center'}
        rowGap={'20px'}
        flexDirection={{ base: 'column', lg: 'row' }} >
        <Box>
          <StyleText texttype="h2" pb="1rem">
            Midle
          </StyleText>
          <StyleText texttype="bodyText" pb={'1rem'}>
            MIDLE is a blockchain based platform that enables trustless exchange of services and value. Midle ensures exchange of value / service between both parties remains transparent, seamless and fair. Therefore, instead of anonymous parties/users to trust each other which is practically impossible in most cases then they should trust the MIDLE system for a fair and smooth transition.
          </StyleText>
          <Flex flexWrap={'wrap'} gap='8px'>
            <StyledButton as={'p'} color='skyblue' variant="outline">Trust for Transactions</StyledButton>
            <StyledButton as={'p'} color='darkblue' variant="outline">Freelance Tool</StyledButton>
            <StyledButton as={'p'} color='orange' variant="outline">Contractors Tool</StyledButton>
          </Flex>
          <StyledGradientText pt='1.5rem' texttype={"bodyBold"}>
            Launching soon
          </StyledGradientText>
        </Box>
        <Box>
          <Image
            src={'https://firebasestorage.googleapis.com/v0/b/seam-rent.appspot.com/o/vase%2Fmiddle%20draft%208aab%20(1)%201.png?alt=media&token=3dd646e5-ca65-4dab-b10b-b890e90b9ac5'}
            maxW={{ md: "587px", base: "325px" }}
            maxH={{ md: "361px", base: "325px" }}
            w="100%"
            h="100%"
          />
        </Box>
      </Flex>
    </Wrapper>

  );

}

export default Midle;