import { Box, useBreakpointValue, Image, Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Chart } from "react-google-charts";
import { StyleText } from '../Typography';
import Wrapper from '../Wrapper';
import vaseFavicon from "../../assets/logovector.svg";


export const data = [
  ["Private Sale", '10'],
  ["Private Sale", 10],
  ["Pre-sale", 10],
  ["Liquidity", 5],
  ["Team", 12],
  ["Ecosystem development", 18],
  ["Marketing", 5],
  ["Advisers", 5],
  ["Reserve (60 months vesting)", 20],
  ["Staking", 10],
  ["Partnerships", 5],
];


export function Tokenomics() {
  const h = useBreakpointValue({ base: '300px', lg: '500px' })
  const label = useBreakpointValue({ base: 'none', lg: '' })
  const height = useMemo(() => h, [h])
  const options = {
    title: "Tokenomics",
    pieHole: 0.3,
    is3D: false,
    // pieSliceText: 'label',
    legend: label,
    backgroundColor: "white"
  };

  return <Wrapper bgColor="white">
    <Box color={'white'}>
      <Chart
        chartType="PieChart"
        width="100%"
        height={height}
        data={data}
        options={options}
        style={{ color: 'white' }}
      />
    </Box>

    <Box>
      <StyleText color={'black'} textAlign='center' mx={'auto'} texttype='bodyText'>Total supply</StyleText>
      <Flex columnGap={'8px'} width={'fit-content'} mx={'auto'}>
        <Image src={vaseFavicon} maxW='20px' maxH='31.39px' />
        <StyleText color={'black'} textAlign='center' texttype='bodyBold'>90,000,000</StyleText>
      </Flex>
    </Box>
  </Wrapper>

}
