import Wrapper from '../../components/Wrapper'
import { Box, Flex, Heading } from '@chakra-ui/react';
import { LoginButton } from './signInButton';

const AirdropV2 = () => {
  return (
    <Wrapper>
      <Box maxW={'400px'} mx={'auto'} minH={'50svh'}>
        <Heading py={3} size='md'>Sign in to proceed to airdrop</Heading>
        <Flex flexDir={'column'} gap={3} mt={'5vh'}>
          <LoginButton />
        </Flex>
      </Box>
    </Wrapper>
  )
}

export default AirdropV2