import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Footer from "../Footer";
import Nav from "../Nav";

const Layout: React.FC = ({ children }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <Box bg="#04071D" color="white">
      <Nav />
      {children}
      <Footer />
    </Box>
  );
};

export default Layout;
