import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
});

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}


const overrides = {
  breakpoints,
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  color: { 
    primary: 'white',
  },
  fonts: {
    body: `'Poppins', sans-serif`,
    timer: `'Orbitron', sans-serif`,
  },
  styles: {
    global: (props: any) => ({
      html: {},
      body: {
        backgroundColor: '#04071D',
      },
      'html,body, * ': {
        fontFamily: `'Poppins', sans-serif`,
        boxSizing: 'border-box',
        p: '0px',
        m: '0px',
      },
    }),
  },
};

export const customTheme = extendTheme(overrides);
