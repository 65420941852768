import React from "react";
import { Box, Flex, List, ListItem } from "@chakra-ui/react";
import Wrapper from "../Wrapper";
import { StyleText } from "../Typography";

const roadmap = [
  {
    quater: "Q1",
    list: [
      // [
      //   'Vase Token fair launch',
      //   'Dex (pancakeswap) listing & liquidity provision.',
      //   'Social media / branding',
      //   'First quarter marketing, advertising and promotion campaign',
      //   'Coinmarketcap, coingecko, Nomics, Dextools, Coinhunter listing',
      // ],
      [
        "Full Marketing Campaign",
        "Team Expansion",
        "Bolster Waitlist Release",
        "VASE (VSE) Launch",
        // 'Private Sale',
        "V1 - V2 migration",
      ],
      [
        "Marketing Campaign(KOLs, Influencers & PR).",
        "Partnership with MM agency.",
        "VSE Presale.",
        "CMC listing.",
        // 'VSE Exchange listings.'
      ],
    ],
  },
  {
    quater: "Q2",
    list: [
      // [
      //   'Core team development',
      //   'Product development / smart contract deployment ',
      //   'Vestake development',
      //   'Vase Raffle development',
      //   'Bolster development',
      // ],
      [
        // 'public presale',
        // 'CEX & DEX listing',
        // 'Bolster Full Launch',
        // 'Auditing',
        // 'Staking',

        "Partnership",
        "Product Development",
        "Private sale",
      ],
      [
        // 'Bolster Beta testing.',
        // 'Bolster official Launch.',
        // 'BP Airdrop release.',
        // 'Staking.'
        "Partnerships",
        "Airdrop",
        "VSE Exchange Listing",
        "Vase Raffle Alpha/Beta Launch.",
        "Vase Raffle integration on multi-chain.",
        "Vase Raffle Full Launch.",
      ],
    ],
  },
  {
    quater: "Q3",
    list: [
      // [
      //   'Organizational Structure',
      // ],
      [
        // 'Partnerships',
        "Continuation of Q2",
      ],
      [
        // 'Vase Raffle Official Launch.'

        "Continuous Community building.",
        "Bolster Alpha testing",
        "Staking",
        "Team Expansion ",
        "Re-branding",
      ],
    ],
  },
  {
    quater: "Q4",
    list: [
      // [
      //   'Seed Funding',
      //   'VaseLabs website launch ',
      // ],
      ["Continuation of Q2"],
      ["TBA"],
    ],
  },
];

const RoadMap = () => {
  return (
    <Wrapper>
      <StyleText
        pb={{ base: "1rem" }}
        textAlign={{ lg: "center" }}
        id="road-map"
        texttype="h2"
        mx={{ lg: "auto" }}
      >
        Road map
      </StyleText>

      <Flex
        justify={"center"}
        pt={{ base: "20px", lg: "40px" }}
        pb={"30px"}
        columnGap="146px"
      >
        <StyleText
          texttype="bodyBold"
          px="30px"
          py="5px"
          borderRadius={"8px"}
          bgColor={"#7879F1"}
        >
          2023
        </StyleText>
        <StyleText
          display={{ base: "none", lg: "inline" }}
          texttype="bodyBold"
          px="30px"
          py="5px"
          borderRadius={"8px"}
          bgColor={"#04535d"}
        >
          2024
        </StyleText>
      </Flex>
      <Flex
        columnGap="1rem"
        rowGap="1rem"
        flexDir={"column"}
        display={{ base: "none", lg: "flex" }}
        flexWrap={{ base: "wrap", lg: "nowrap" }}
      >
        {roadmap.map((map, index) => {
          return (
            <Flex
              key={index}
              flexDir={{ base: "column-reverse", lg: "row" }}
              width={"100%"}
              alignItems="center"
            >
              <Box w={"100%"} textAlign={{ base: "left", lg: "right" }}>
                <List key={index} pb="2rem" spacing="8px">
                  {map.list[0].map((list, idx) => (
                    <ListItem fontSize={16} key={idx + list}>
                      <StyleText texttype="roadMapText">{list}</StyleText>
                    </ListItem>
                  ))}
                </List>
                {/* </SlideFade> */}
              </Box>

              <StyleText
                px={{ base: "16px", lg: "40px" }}
                texttype="roadMapQ"
                textAlign={{ base: "left", lg: "center" }}
              >
                {map.quater}
              </StyleText>

              <Box w={"100%"} textAlign="left">
                <List key={index} pb="2rem" spacing="8px">
                  {map.list[1].map((list, idx) => (
                    <ListItem fontSize={16} key={idx + list}>
                      <StyleText texttype="roadMapText">{list}</StyleText>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Flex>
          );
        })}
      </Flex>
      {/* Mobile */}
      <Box display={{ base: "block", lg: "none" }}>
        {roadmap.map((map, index) => {
          return (
            <Flex
              key={index}
              flexDir={{ base: "column", lg: "row" }}
              width={"100%"}
              alignItems="center"
            >
              <StyleText
                px={{ base: "16px", lg: "40px" }}
                texttype="roadMapQ"
                textAlign={{ base: "left", lg: "center" }}
              >
                {map.quater}
              </StyleText>
              <Box w={"100%"} textAlign={{ base: "left", lg: "right" }}>
                <List key={index} pb="2rem" spacing="8px">
                  {map.list[0].map((list, idx) => (
                    <ListItem fontSize={16} key={idx + list}>
                      <StyleText texttype="roadMapText">{list}</StyleText>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Flex>
          );
        })}
        <Flex
          justify={"center"}
          pt={{ base: "20px", lg: "40px" }}
          pb={"30px"}
          columnGap="146px"
        >
          <StyleText
            display={{ base: "inline" }}
            texttype="bodyBold"
            px="30px"
            py="5px"
            borderRadius={"8px"}
            bgColor={"#04535d"}
          >
            2024
          </StyleText>
        </Flex>
        {roadmap.map((map, index) => {
          return (
            <Flex
              key={index}
              flexDir={{ base: "column" }}
              width={"100%"}
              alignItems="center"
            >
              <StyleText
                px={{ base: "16px", lg: "40px" }}
                texttype="roadMapQ"
                textAlign={{ base: "left", lg: "center" }}
              >
                {map.quater}
              </StyleText>

              <Box w={"100%"} textAlign="left">
                <List key={index} pb="2rem" spacing="8px">
                  {map.list[1].map((list, idx) => (
                    <ListItem fontSize={16} key={idx + list}>
                      <StyleText texttype="roadMapText">{list}</StyleText>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Flex>
          );
        })}
      </Box>
    </Wrapper>
  );
};

export default RoadMap;
