import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react'
import { baseColor, blue } from './color'

export type AirdropAccordionProps = {
  description: string,
  tasks: TaskProps[],
  isAllTaskComplete: boolean,
  title: string
}

const AirdropAccordion = ({ description, tasks, isAllTaskComplete, title }: AirdropAccordionProps) => {
  return (
    <Accordion allowToggle w={'100%'} >
      <AccordionItem bg={isAllTaskComplete ? 'green.900' : baseColor} borderRadius={'15px'} border={'none'}>
        <h2>
          <AccordionButton px={5} py={5} >
            <Box fontWeight={600} textTransform={'capitalize'} as="span" flex='1' textAlign='left'>
              {title ?? 'Complete Task'}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text pb={3} fontSize={'small'} fontWeight={400}>{description}</Text>
          {tasks.length && tasks.map((task) => <Task key={task.task} {...task} />)}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default AirdropAccordion


export type TaskProps = {
  task: string,
  points: number,
  isComplete: boolean,
  onClickTask: () => void
}

const Task = (props: TaskProps) => {
  const { isComplete, onClickTask, points, task } = props
  return (
    <Flex onClick={onClickTask} bg={'#1b1d39'} _hover={{ bgColor: '#101125', cursor: 'pointer' }} borderRadius={'10px'} mb={2} justify={'space-between'} alignItems={'center'} px={5} py={3} gap={3}>
      <Box >
        <Text fontSize={{ base: 'small', lg: 'large' }} fontWeight={600}>{task}</Text>
        <Text fontSize={'x-small'} fontWeight={'600'}> {points} point</Text>
      </Box>
      {/* <Text
        fontSize={['small']}
        px={{ base: 4, lg: 6 }}
        py={{ base: 2, lg: 3 }}
        borderRadius='10px'
        fontWeight={500}
        color={isComplete ? 'white' : 'gray.400'}
        bgColor={isComplete ? blue : '#D9D9D922'}
      >{isComplete ? 'Completed' : 'Incomplete'}</Text> */}
    </Flex>
  )
}