import {
  Box,
  Flex,
  Link as ChakraLink
} from "@chakra-ui/react";
import Wrapper from "../Wrapper";
// @ts-ignore
import vaselabsWhitePaper from "../../assets/pdf/Vaselabswhitepaper.pdf";
import { StyleText } from "../Typography";
import heroBg from '../../assets/hero_bg.svg'
import { StyledButton } from "../styledButton";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Wrapper bgImage={heroBg}>
      <Box>
        <StyleText texttype="h1">
          Vase Labs
        </StyleText>
        <StyleText texttype="bodyBold" color={'rgba(255, 255, 255, 0.7)'} pb={'1rem'}>
          An ecosystem that provides diverse set of innovative tech solutions via the Blockchain
        </StyleText>
        <Flex columnGap={'18px'}>
          <StyledButton
            as={Link}
            color='skyblue'
            variant="solid"
            borderRadius="8px"
            // href="https://pancakeswap.finance/swap?outputCurrency=0x737ed8c950b7F8e35ef23c85Caf449569663d9e6"
            to='/airdrop-v2'
            p={'10px 16px'}
          >
            Airdrop
          </StyledButton>
          <StyledButton
            as={Link}
            to={'/launchpad'}
            color={'purple'}
            variant='outline'
            // href={'https://forms.gle/mRXFsyeSbTqGdjLn8'}
            // target='_blank'
            borderRadius="8px"
            transition=".23s linear"
            p={'10px 16px'}
          >
            Join Pre-sale
          </StyledButton>
          {/* <StyledButton
            as={Link}
            color={'purple'}
            variant='outline'
            to={'/migrate'}
            borderRadius="8px"
            transition=".23s linear"
            p={'10px 16px'}
          >
            Migrate to V2
          </StyledButton> */}
          <StyledButton
            display={'none'}
            color={'purple'}
            variant='outline'
            target={'_blank'}
            href={vaselabsWhitePaper}
            borderRadius="8px"
            transition=".23s linear"
            p={'10px 16px'}
          >
            Read white paper
          </StyledButton>
        </Flex>
      </Box>
    </Wrapper>
  );
};

export default Hero;
