import { Box, Button, Flex, Heading, Img, Input, Text, VStack, useClipboard, useDisclosure, useToast, Badge } from '@chakra-ui/react'
import { useState } from 'react'
import { FiCopy, FiFile } from 'react-icons/fi'
import step1image from '../assets/twStep1.png'
import step2image from '../assets/twStep2.png'
import CustomModal from '../customModal'
import { apiInstance, endpoints } from '../../../endpoint'
import { convertBool, verificationLink } from '../../../utils'


const PostModalContent = (props) => {

  const { type, content, id: task_id, onClose } = props
  const [link, setLink] = useState()
  const { hasCopied, onCopy } = useClipboard(content)
  const toast = useToast()

  const handleTwitterLink = () => {
    const userObj = localStorage.getItem('userObject')
    const { email, username, id } = JSON.parse(userObj)
    const { success } = verificationLink(username.toLowerCase()).safeParse(link.toLowerCase())

    if (!success) return toast({ status: 'error', title: 'Invalid Link' })

    apiInstance.post(endpoints.verifyPost,
      { email, type, username, link, id, task_id }
    ).then(() => toast({ status: 'success', title: 'Verifying Link' })).finally(onClose)
  }

  return (
    <Box p={4}>
      <VStack spacing={6}>
        <Flex gap={4} w={'100%'} justify={'space-between'} alignItems={'center'}>
          <Flex flexDir={'column'} gap={3} maxW={'600px'}>
            <Heading size={'md'}>Step 1</Heading>
            <Text>Copy the content and post on Twitter </Text>
            <Box>
              <Button colorScheme="twitter" px={10} onClick={onCopy}>{hasCopied ? <FiCopy fontSize={'1.2rem'} /> : <FiFile fontSize={'1.2rem'} />} {hasCopied ? 'Copied' : 'Copy'}</Button>
            </Box>
          </Flex>
          <Box w={'120px'} display={['none', 'block']}><Img w={'100%'} src={step1image} /></Box>
        </Flex>

        <Flex gap={4} w={'100%'} justify={'space-between'} alignItems={'center'}>
          <Flex flexDir={'column'} gap={3} maxW={'600px'}>
            <Heading size={'md'}>Step 2</Heading>
            <Text>
              On your tweet, find the share button. Copy link and paste it here. Click the
              button to verify.
            </Text>
            <Input required placeholder="Paste link here" value={link} onChange={e => setLink(e.target.value)} />
            <Box>
              <Button disabled={!link} onClick={handleTwitterLink} colorScheme="twitter" px={8}>Verify</Button>
            </Box>
          </Flex>
          <Box w={'120px'} display={['none', 'block']}><Img w={'100%'} src={step2image} /></Box>
        </Flex>
      </VStack>
    </Box>
  )
}

const PostTask = (props) => {
  const { title, point, content, isLock, isActive } = props
  const lock = convertBool(isLock)
  const active = convertBool(isActive)
  const { onOpen, ...rest } = useDisclosure()
  return (
    <div>
      <Flex onClick={lock || !active ? () => { } : onOpen} bg={'#1b1d39'} _hover={{ bgColor: '#101125', cursor: 'pointer' }} borderRadius={'10px'} mb={2} justify={'space-between'} alignItems={'center'} px={5} py={3} gap={3}>
        <Box >
          <Text fontSize={{ base: 'small', lg: 'large' }} fontWeight={600}>{title}</Text>
          <Text fontSize={'x-small'} fontWeight={'600'}> {point} point</Text>
        </Box>
        <Flex gap={4} width={'fit-content'} px={3}>
          {active ? <Badge colorScheme='green'>Active</Badge> : <Badge colorScheme='orange'>Inactive</Badge>}
          {lock && <Badge colorScheme='red'>Locked</Badge>}
        </Flex>
      </Flex>
      <CustomModal {...rest}>
        <PostModalContent {...props} post={content} onClose={rest.onClose} />
      </CustomModal>
    </div>
  )
}

export default PostTask

