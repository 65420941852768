

import { Box, Flex, Image, Input, InputGroup, InputRightElement, Link, Progress, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { StyleText } from '../components/Typography'
import Wrapper from '../components/Wrapper'
import vaseFavicon from "../assets/logovector.svg";
import { StyledButton } from '../components/styledButton';
import { ConnectButton, useNotification } from "web3uikit";
import { useMoralis, useWeb3Contract, useChain, useMoralisWeb3Api } from 'react-moralis'
import { ethers } from "ethers";
import axios from "axios";
import styled from '@emotion/styled';

const IFrame = styled('iframe')`
    aspect-ratio: 16/9;
    width: 100%;
    min-width: 300px;
    @media (min-width: 1300px) {
        min-width: 620px;
        margin-left: 0;
    }
`

const Status = ({ state }) => {
  if (state) return <StyleText borderRadius={'16px'} px='8px' bgColor='rgba(75, 199, 132, 0.45)' texttype='tiny' color={'#58FFA5'}>{state}</StyleText>
  return <StyleText borderRadius={'16px'} px='8px' bgColor='rgba(220, 99, 102, 0.45)' texttype='tiny' color={'#ff5877'}>{state}</StyleText>
}

const grid = [
  { name: 'Current VASE price', amount: '$0.0556' },
  { name: 'Supply for sale', amount: '9,000,000' },
  { name: 'Private Sale goal', amount: '$500,400' },
  { name: 'Vesting Period', amount: '10 months (5% monthly)' },
  { name: 'First release after locking period', amount: '50%' },
]

const vestingSchedule = `
Tokens will be locked 40 days after Private sale. Token release / distribution: 1 month’s interval (10 months linear vesting)
`
const PrivateSales = () => {

  const vaseTokenAddress = "0x27c77063a7131969863c4902e6C6b12ec2Ab7a93";
  const busdAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
  const saleAddress = "0x800ca12881641131f3343B1176711295d21553fD";
  const privateVesting = "0xadB2d718757063c2b441Eb64996E888Ee1C9f30C";
  const chainIdLocal = '0x38'

  const [buyDollarValue, setBuyDollarValue] = useState("0")
  const Web3Api = useMoralisWeb3Api();
  const { chainId, chain, switchNetwork } = useChain();
  const [vaseBoughtFromProtocol, setVaseProtocolBought] = useState("0")
  const [runningVaseBalProtocol, setRunningVaseBalProtocol] = useState("0")
  const [busdBalFromProtocol, setBusdProtocolBal] = useState("0")
  const [busdWalletBalValue, setBusdWalletBal] = useState("0")
  const [busdSold, setBusdSold] = useState("0")
  const [busdSpent, setBusdSpent] = useState("0")
  const [salesStatus, setSalesStatus] = useState("0")
  const [widCount, setWidCount] = useState("0")
  const [monthlyP, setmonthlyP] = useState("0")
  const [firstPay, setFirstPay] = useState("0")
  const [buyers, setBuyers] = useState("0")
  const [refCode, setRefCode] = useState("")

  const [vaseBoughtFromProtocol2, setVaseProtocolBought2] = useState("0")
  const [busdSpent2, setBusdSpent2] = useState("0")



  const { account, isWeb3Enabled, Moralis, isInitialized } = useMoralis();

  const { runContractFunction } = useWeb3Contract();
  const dispatch = useNotification();

  const { runContractFunction: getBusdTokenBalance } = useWeb3Contract({
    abi: [{
      "constant": true,
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: busdAddress,
    functionName: "balanceOf",
    params: {
      account: account
    },
  })

  // get total busd sales
  const { runContractFunction: getTotalSales } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getTotalBusd",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getTotalBusd"
  })

  // get sales status
  const { runContractFunction: getSalesStatus } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getSalesStatus",
      "outputs": [
        {
          "internalType": "enum VasePrivateSale.PresaleState",
          "name": "",
          "type": "uint8"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getSalesStatus"
  })

  // total user vase balance
  const { runContractFunction: getBoughtVase } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getUserVaseBalance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getUserVaseBalance"
  })

  // total busd spent
  const { runContractFunction: getSpentBusd } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getUserBusdSpent",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getUserBusdSpent"
  })

  // user monthly vase pay
  const { runContractFunction: getUserMonthlyPay } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getUserMonthlyPay",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: privateVesting,
    functionName: "getUserMonthlyPay"
  })

  // user first vase pay
  const { runContractFunction: getUserFirstPay } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getUserFirstPay",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getUserFirstPay"
  })

  // get user wid count
  const { runContractFunction: getWidCount } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getWidCount",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getWidCount"
  })

  // get all buyers
  const { runContractFunction: getBuyers } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getAllBuyers",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getAllBuyers"
  })

  let approveOptions = {
    abi: [{
      "constant": false,
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }],
    contractAddress: busdAddress,
    functionName: "approve",
  };

  let buyOptions = {
    abi: [{
      "inputs": [
        {
          "internalType": "uint256",
          "name": "busdAmount",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "busd",
          "type": "address"
        }
      ],
      "name": "buyVase",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"

    }],
    contractAddress: saleAddress,
    functionName: "buyVase"
  }

  // vase withdraw
  let withdrawOption = {
    abi: [{
      "inputs": [
        {
          "internalType": "uint256",
          "name": "totalVase",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "totalBusd",
          "type": "uint256"
        }
      ],
      "name": "withdrawMonthlyVesting",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }],
    contractAddress: privateVesting,
    functionName: "withdrawMonthlyVesting",

  }

  useEffect(() => {
    // get stake history
    if (chainId == chainIdLocal) {
      updateFix();

    }
    if (isWeb3Enabled && chainId == chainIdLocal) {
      UpdateStats();
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('ref') != null) {
      let refCode = urlParams.get('ref');
      setRefCode(refCode);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isWeb3Enabled, isInitialized, busdBalFromProtocol])

  async function updateFix() {
    //total busd sold
    const busd = (
      await getTotalSales({ onError: (error) => console.log(error) })
    ).toString();


    const formatedBusdBalSold = ethers.utils.formatUnits(busd, "ether")
    const vaseSold = parseInt((formatedBusdBalSold * 1799) / 100);
    const percet = (vaseSold / 9000000) * 100;
    setBusdSold(Math.floor(percet));



    //all buyers
    const buys = (
      await getBuyers({ onError: (error) => console.log(error) })
    ).toString();
    setBuyers(parseInt(buys))


    // get sales status
    const saleStat = (
      await getSalesStatus({ onError: (error) => console.log(error) })
    ).toString();



    if (parseInt(saleStat) == 0) {
      setSalesStatus("Paused");
    } else if (parseInt(saleStat) == 1) {
      setSalesStatus("Active");
    } else {
      setSalesStatus("Stopped");
    }

  }

  async function UpdateStats() {

    //get busd wallet balance
    const busdBalFromWallet = (
      await getBusdTokenBalance({ onError: (error) => console.log(error) })
    ).toString();


    const formatedBusdBalFromWallet = ethers.utils.formatUnits(busdBalFromWallet, "ether")
    setBusdWalletBal(Math.floor(formatedBusdBalFromWallet));

    // user vase bought
    const vaseBought = (
      await getBoughtVase({ onError: (error) => console.log(error) })
    ).toString();
    setVaseProtocolBought2(vaseBought);
    const formatedVaseBt = ethers.utils.formatUnits(vaseBought, "ether")
    setVaseProtocolBought(formatedVaseBt)

    //user busd spent
    const spentBusd = (await getSpentBusd({ onError: (error) => console.log(error) })).toString();
    setBusdSpent2(spentBusd);
    const busdSpentFormated = ethers.utils.formatUnits(spentBusd, "ether")
    setBusdSpent(busdSpentFormated)

    // user monthly pay
    const monthlyPays = (
      await getUserMonthlyPay({ onError: (error) => console.log(error) })
    ).toString();
    const formatedVaseMonthPay = ethers.utils.formatUnits(monthlyPays, "ether")
    setmonthlyP(formatedVaseMonthPay)
    // console.log(`user monthly vase pay: ${formatedVaseMonthPay}`)

    // user first pay
    const firstPays = (
      await getUserFirstPay({ onError: (error) => console.log(error) })
    ).toString();
    const formatedFirstPay = ethers.utils.formatUnits(firstPays, "ether")
    setFirstPay(formatedFirstPay)
    // console.log(`first user vase pay: ${formatedFirstPay}`)



    // running vase balance
    // const widCt = (
    //   await getWidCount({ onError: (error) => console.log(error) })
    // ).toString();
    // setWidCount(widCt)

    // if (parseInt(widCount) < 1) {
    //   setVaseProtocolBought(Math.floor(formatedVaseBt));
    // }
    // else if (parseInt(widCount) == 1) {
    //   const vaseBl = parseInt(formatedVaseBt - firstPay);
    //   setVaseProtocolBought(Math.floor(vaseBl));
    // }
    // else {
    //   const ct = parseInt(widCount) - 1;
    //   const current = parseInt(ct * monthlyP) + firstPay;
    //   const vaseBl = parseInt(formatedVaseBt - current);
    //   setVaseProtocolBought(Math.floor(vaseBl));

    // }


  }


  const handleBuyValueChange = e => {
    setBuyDollarValue(String(e.target.value))
  }

  async function handleBuySubmit(e) {
    e.preventDefault();
    const minSales = ethers.utils.parseUnits("100", "ether").toString();
    // const minShow = minStaking.toLocaleString('en-US');


    const amountToApprove = buyDollarValue
    approveOptions.params = {
      amount: ethers.utils.parseUnits(amountToApprove, "ether").toString(),
      spender: saleAddress
    }
    if (!account) {
      dispatch({
        type: "error",
        message: "No wallet connected",
        title: "private sale",
        position: "topR",
      })
      return;
    }

    if (parseInt(amountToApprove) > parseInt(busdWalletBalValue)) {
      dispatch({
        type: "error",
        message: "Insufficient busd balance",
        title: "private sale",
        position: "topR",
      })
      return;
    }
    if (parseInt(amountToApprove) < parseInt(100)) {
      dispatch({
        type: "error",
        message: "Minimum sale is 100 busd",
        title: "private sale",
        position: "topR",
      })
      return;
    }


    const txn = await runContractFunction({
      params: approveOptions,
      onError: (error) => {
        console.log(error)
        dispatch({
          type: "Error",
          message: error,
          title: "Approval Error",
          position: "topL",
        })
      },
      onSuccess: () => {
        handleApproveSuccess(approveOptions.params.amount)
      },

    })
    await txn.wait(1);
  }


  const handleVolumes = async (code, volume) => {
    if (!refCode) return

    const apiUrl =
      process.env.REACT_APP_API_URL ||
      "https://vaselabs-client-oq4ks.ondigitalocean.app/api";

    try {
      const response = await axios.post(
        `${apiUrl}/users/private`,
        { code: code, volume: volume },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },)
      console.log(response);
    } catch (error) {
      console.log({ error })
    }
  }


  async function handleApproveSuccess(amountToBuyFormated) {

    buyOptions.params = {
      busdAmount: amountToBuyFormated,
      busd: busdAddress
    }

    const tx = await runContractFunction({
      params: buyOptions,
      onError: (error) => console.log(error),
      onSuccess: () => {
        dispatch({
          type: "success",
          message: "please join the exclusive group",
          title: "private sale succesful",
          position: "topR",
        })

        // if (window.confirm("Click Ok to Join the exclusive investors group")) {
        //   window.open('https://t.me/+3NW2qvVRdjIyZjBk');
        // }
        if (refCode != null && refCode != "") {
          handleVolumes(refCode, buyDollarValue);
        }

      }
    })
    // await tx.wait(1);
  }

  async function handleWithdraw(e) {
    e.preventDefault();


    withdrawOption.params = {
      totalVase: ethers.utils.parseUnits(vaseBoughtFromProtocol, "ether").toString(),
      totalBusd: ethers.utils.parseUnits(busdSpent, "ether").toString()

    }
    const tx = await runContractFunction({
      params: withdrawOption,
      onError: (error) => console.log(error),
      onSuccess: () => {
        dispatch({
          type: "success",
          message: "Transaction successful",
          title: "withdrawal",
          position: "topR",
        })
      }
    })
  }

  return (
    <Wrapper>
      <Flex justify={{ base: 'center', lg: 'space-between' }} alignItems='center' py='1rem' flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
        <StyleText texttype='h1'>Vase Private Sales</StyleText>
        <ConnectButton moralisAuth={true}></ConnectButton>
        {/* <Button colorScheme={'green'}>Connect wallet</Button> */}
      </Flex>
      {/* status */}

      <Box maxW='700px' p={{ base: '1rem', lg: '2rem' }} bgColor='rgba(255, 255, 255, 0.12)' borderRadius={'16px'}>
        {/* <Flex justify={'space-between'} alignItems='center'>
          <Flex columnGap={'8px'} alignItems='center'>
            <Image src={vaseFavicon} maxW='35px' maxH='46.39px' />
            <StyleText texttype='bodyBold'>vase</StyleText>
          </Flex>
          <Box>
            <Flex columnGap={'4px'}>
              <StyleText texttype='tiny'>sales status</StyleText>
              <Status state={salesStatus} />
            </Flex>
          </Box>
        </Flex> */}
        {/* progress bar */}
        {/* {
          isWeb3Enabled ? (
            <Box py='1rem'>
              <StyleText texttype='bodyBold'>Private sale Progress</StyleText>
              <Progress bgColor={'rgba(217, 217, 217, 0.16)'} value={busdSold} borderRadius='8px' />
              <Flex justify={'space-between'} pt='.2rem'>
                <StyleText texttype='tiny'>{busdSold}</StyleText>
                <StyleText texttype='tiny'>9,000,000(100%)</StyleText>
              </Flex>
            </Box>
          ) : (
            <Box py='1rem'>
            </Box>
          )
        } */}

        {/* grid */}
        {/* <Box>
          {grid.map(({ amount, name }) =>
          (<Flex key={name} justify={'space-between'} alignItems='center' py='8px' borderBottom={'1px gray solid'} flexWrap={{ base: 'wrap', lg: 'nowrap ' }}>
            <StyleText texttype='bodyText' color={'#ffffff99'}>{name}</StyleText>
            <StyleText texttype='bodyBold'>{amount}</StyleText>
          </Flex>)
          )}
          <Flex justify={'space-between'} alignItems='center' py='8px' borderBottom={'1px gray solid'} flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
            <StyleText texttype='bodyText' color={'#ffffff99'}>Contract Address</StyleText>
            <Link target={'_blank'} href='https://bscscan.com/address/0x800ca12881641131f3343B1176711295d21553fD'><StyleText color={'skyblue'} texttype='bodyBold'>https://bscscan.com/address/0x800ca12881641131f3343B1176711295d21553fD</StyleText></Link>
          </Flex>
          <Flex justify={'space-between'} alignItems='center' py='8px' borderBottom={'1px gray solid'} flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
            <StyleText texttype='bodyText' color={'#ffffff99'}>VASE(VSE) contract address</StyleText>
            <StyleText texttype='bodyBold'>0x27c77063a7131969863c4902e6C6b12ec2Ab7a93</StyleText>
          </Flex>

          <Box mt={'2.5rem'} py={'1rem'} borderTop={'2px gray solid'}>
            <StyleText texttype='h2'>Vesting Schedule</StyleText>
            <StyleText texttype='bodyText' color={'#ffffff99'}>
              {vestingSchedule}
            </StyleText>
          </Box>
        </Box> */}
      </Box>
      {/* withdrawal */}
      <Box mt={'2rem'} p={{ base: '1rem', lg: '2rem' }} bgColor='rgba(255, 255, 255, 0.12)' borderRadius={'16px'}>
        <Flex columnGap={'1rem'} rowGap='1rem' flexDir={{ base: 'column', lg: 'row' }}>
          {/* <Box flexGrow={1} >
            <StyleText texttype='h2'>Participate</StyleText>
            <Flex justify={'space-between'} alignItems='center' py={'1rem'}>
              <StyleText texttype='tiny'>Total Investors</StyleText>
              <StyleText texttype='tiny'>{buyers}</StyleText>
            </Flex>
            <Box bgColor={'rgba(4, 7, 29, 0.64)'} p='1rem' borderRadius={'8px'}>
              <Flex justify={'space-between'} alignItems='center' py={'.5rem'}>
                <StyleText color={'#42B1FF'} texttype='tiny'>Min.Purchase</StyleText>
                <StyleText texttype='roadMapText'>$100 BUSD</StyleText>
              </Flex>
              <Flex justify={'space-between'} alignItems='center' py={'.5rem'}>
                <StyleText color={'#42B1FF'} texttype='tiny'>Max.Purchase</StyleText>
                <StyleText texttype='roadMapText'>$10,000 BUSD</StyleText>
              </Flex>

              <form onSubmit={handleBuySubmit} >
                <label><StyleText texttype='bodyText'>Amount</StyleText></label>
                <InputGroup>
                  <Input placeholder='0' onChange={handleBuyValueChange} variant={'filled'} value={buyDollarValue} />
                  <InputRightElement
                    pointerEvents='none'
                    color='gray.300'
                    fontSize='1.2em'
                    children='$'
                  />
                </InputGroup>
                <StyledButton type='submit' mt={'1rem'} variant='solid' color={'skyblue'} w='100%'>Buy with BUSD </StyledButton>
              </form>
            </Box>
          </Box> */}

          <Box flexGrow={1}>
            <StyleText texttype='h2'>Vesting Withdrawal</StyleText>
            {/* <StyleText texttype='tiny' py={'1rem'}> withdraw monthly vesting</StyleText> */}
            <Box bgColor={'rgba(4, 7, 29, 0.64)'} p='1rem' borderRadius={'8px'}>
              <StyleText texttype='tiny' pt={'.5rem'}>Total VSE</StyleText>
              <Flex columnGap={'8px'} alignItems='center'>
                <Image src={vaseFavicon} maxW='25px' maxH='36.39px' />
                <StyleText texttype='h2'> {vaseBoughtFromProtocol} VASE </StyleText>
              </Flex>
            </Box>
            <Box bgColor={'rgba(4, 7, 29, 0.64)'} p='1rem' borderRadius={'8px'}>
              <StyleText texttype='tiny' pt={'.5rem'}>Monthly Vesting</StyleText>
              <Flex columnGap={'8px'} alignItems='center'>
                <Image src={vaseFavicon} maxW='25px' maxH='36.39px' />
                <StyleText texttype='h2'> {monthlyP} VASE </StyleText>
              </Flex>
            </Box>

            <Box bgColor={'rgba(4, 7, 29, 0.64)'} p='1rem' borderRadius={'8px'} mt='1rem'>
              <form onSubmit={handleWithdraw}>
                {/* <label><StyleText texttype='bodyText'>Amount</StyleText></label>
                  <InputGroup mb='.5rem'>
                    <Input placeholder='0' variant={'filled'} />
                    <InputRightElement
                      pointerEvents='none'
                      color='gray.300'
                      fontSize='1.2em'
                      children='$'
                    />
                  </InputGroup>
                  <label><StyleText texttype='bodyText'>Address</StyleText></label>
                  <Input placeholder='00xxxrxttyvuvsgvsvvvvtvybbyb' variant={'filled'} /> */}

                <StyledButton mt={'1rem'} type="submit" variant='solid' color={'skyblue'} w='100%'>Withdraw</StyledButton>
              </form>
            </Box>


          </Box>
        </Flex>
      </Box>
      {
        salesStatus == "Stopped" ?
          (

            <Box mt={'2rem'} p={{ base: '1rem', lg: '2rem' }} bgColor='rgba(255, 255, 255, 0.12)' borderRadius={'16px'}>
              <StyleText mx='auto' textAlign={'center'} texttype='h2'>Withdrawal Transactions</StyleText>
              <TableContainer pt={'1rem'}>
                <Table variant='simple' colorScheme='blue'>
                  <Thead>
                    <Tr>
                      <Th><StyleText texttype='bodyBold'>S/N</StyleText></Th>
                      <Th><StyleText texttype='bodyBold'>Amount</StyleText></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td><StyleText texttype='bodyText'>1</StyleText></Td>
                      <Td><StyleText texttype='bodyText'>100</StyleText></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>

          ) : (
            <Box></Box>
          )
      }

    </Wrapper>
  )
}

export default PrivateSales