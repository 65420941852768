import { Box, Button, Flex, Heading, Img, Input, Text, VStack, useClipboard } from "@chakra-ui/react"
import { BsTwitterX } from "react-icons/bs"
import { FiCopy, FiFile } from 'react-icons/fi'
import step1image from './assets/twStep1.png'
import step2image from './assets/twStep2.png'
import { LoginSocialTwitter } from 'reactjs-social-login'
import { useCallback, useEffect, useState } from "react"
import axios from "axios";


// const REDIRECT_URI = 'https://vaselabs.io/airdrop-v2/dashboard';
const REDIRECT_URI = 'http://localhost:3000/airdrop-v2';
const apiUrl =
  process.env.REACT_APP_API_URL ||
  "https://vaselabs-client-oq4ks.ondigitalocean.app/api";

const ConnectTwitter = () => {
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState();

  const { hasCopied, onCopy, value } = useClipboard('Lorem ipsum dolor sit, amet consectetur adipisicing elit.Exercitationem aperiam esse sequi dignissimos quo aliquam optio molestiae rem eligendi earum.Asperiores necessitatibus dolor dolorem repellat suscipit fuga modi, velit amet!')

  const onSuccess = useCallback(({ provider, data }) => {
    setProvider(provider);
    setProfile(data);
    console.log({ data, provider })
    return
  }, []);

  const onLogoutSuccess = useCallback((event) => {
    setProfile(null);
    setProvider('');
    console.log('logout success');
  }, []);

  const onFail = useCallback((reject) => { console.log({ reject }) }, []);

  const handleSubmit = () => {
    window.location.href = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITTER_APP_ID}&redirect_uri=${REDIRECT_URI}?twitter&scope=tweet.read%20tweet.write%20follows.read%20follows.write%20like.read%20like.write%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`
  }
  const [userObject, setUserObj] = useState(null)
  const [input, setInput] = useState()
  const handleTwitterLink = () => {

    const twitterUrl = input;
    const username = userObject.username;
    const total = userObject.total;
    const id = userObject.id;

    const twitterRegex = /https?:\/\/x\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/\d+/;
    const regex = /x\.com\/([^\/?]+)/;

    if (twitterRegex.test(twitterUrl)) {

      const match = twitterUrl.match(regex);

      if (match && match[1]) {
        const user = match[1];
        if (user === username) {
          // alert("all good to go");

          const verifyPost = async () => {
            try {
              const response = await axios.post(
                `${apiUrl}/users/verifyPost`,
                { username: username, post: twitterUrl, total: total, id: id },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                  },
                }
              );
              alert(response.data.message);
              console.log(response.data);
            } catch (error) {
              console.error(error);
            }
          };
          verifyPost();
        } else {
          alert("username mismatch on twitter(X) link");
        }
      }

    } else {
      alert("Not a valid Twitter post link.");
    }

  }



  useEffect(() => {
    const localObj = localStorage.getItem('userObject')
    if (!localObj) return;
    const userObj = JSON.parse(localObj)
    setUserObj(userObj)
  }, [])



  return (
    <Box p={4}>
      <VStack spacing={6}>
        <Flex gap={4} w={'100%'} justify={'space-between'} alignItems={'center'}>
          <Flex flexDir={'column'} gap={3} maxW={'600px'}>
            <Heading size={'md'}>Step 1</Heading>
            <Text>Copy the content and post on Twitter </Text>
            <Box>
              <Button colorScheme="twitter" px={10} onClick={onCopy}>{hasCopied ? <FiCopy fontSize={'1.2rem'} /> : <FiFile fontSize={'1.2rem'} />} {hasCopied ? 'Copied' : 'Copy'}</Button>
            </Box>
          </Flex>
          <Box w={'120px'} display={['none', 'block']}><Img w={'100%'} src={step1image} /></Box>
        </Flex>

        <Flex gap={4} w={'100%'} justify={'space-between'} alignItems={'center'}>
          <Flex flexDir={'column'} gap={3} maxW={'600px'}>
            <Heading size={'md'}>Step 2</Heading>
            <Text>
              On your tweet, find the share button. Copy link and paste it here. Click the
              button to verify your account
            </Text>
            <Input required placeholder="Paste link here" value={input} onChange={e => setInput(e.target.value)} />
            <Box>
              <Button disabled={!input} onClick={handleTwitterLink} colorScheme="twitter" px={8}>Verify</Button>
            </Box>
          </Flex>
          <Box w={'120px'} display={['none', 'block']}><Img w={'100%'} src={step2image} /></Box>
        </Flex>
      </VStack>
    </Box>
  )
}

export default ConnectTwitter

