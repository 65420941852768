import { Badge, Box, Flex, Heading, Input, InputGroup, InputRightElement, Text, useClipboard, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { useMoralis, useWeb3Contract, useChain, useMoralisWeb3Api } from 'react-moralis';
import { ethers } from "ethers";





const Affiliate = () => {

  const [refCode, setRefCode] = useState("");

  let link = `https://vaselabs.io/launchpad?ref=${refCode}`;

  const { account, isWeb3Enabled, Moralis, isInitialized } = useMoralis();
  const saleAddress = "0x81c78c422756303a5DE2f211eD08b7B70A1697E7";

  const { chainId, chain, switchNetwork } = useChain();
  const { runContractFunction } = useWeb3Contract();

  const chainIdLocal = '0x38'

  const { hasCopied, onCopy, value } = useClipboard(link)
  const [usdtReferral, setUsdtReferral] = useState("0");
  const [bnbReferral, setBnbReferral] = useState("0");
  const [refCount, setRefCount] = useState([]);


  const toast = useToast();
  useEffect(() => {
    if (hasCopied) toast({
      title: 'Copied',
      status: 'success',
      description: 'Referral link copied to clipboard',
      position: 'bottom'
    })

    if (isWeb3Enabled && chainId == chainIdLocal) {
      UpdateStats();
    }

  }, [hasCopied, toast, account, isWeb3Enabled, isInitialized])



  //get users usdt referral amount
  const { runContractFunction: getUsdtReferal } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getUsdtReferal",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getUsdtReferal"
  })

  //get users bnb referral amount
  const { runContractFunction: getBnbReferal } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getBnbReferal",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getBnbReferal"
  })

  //user all data
  const { runContractFunction: getUserData } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "userData",
      "outputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "monthlyPay",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "firstRelease",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "totalVase",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "runningVaseBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "widCount",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "tokenPurchaseAmount",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "usdtReferralAmount",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "bnbReferralAmount",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "refCount",
              "type": "uint256"
            },
            {
              "internalType": "string",
              "name": "refCode",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "referrer",
              "type": "string"
            }
          ],
          "internalType": "struct VasePresale.User",
          "name": "",
          "type": "tuple"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "userData"
  })


  //get refCode
  const { runContractFunction: getRefCode } = useWeb3Contract({
    abi: [{
      "inputs": [],
      "name": "getRefCode",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    contractAddress: saleAddress,
    functionName: "getRefCode"

  })


  async function UpdateStats() {

    // user usdt referral
    const usdtRef = (
      await getUsdtReferal({ onError: (error) => console.log(error) })
    ).toString();
    const formatedUsdt = ethers.utils.formatUnits(usdtRef, "ether")
    setUsdtReferral(formatedUsdt)

    //bnb referral
    const bnbtRef = (
      await getBnbReferal({ onError: (error) => console.log(error) })
    ).toString();
    const formatedBnb = ethers.utils.formatUnits(bnbtRef, "ether")
    setBnbReferral(formatedBnb);

    //user data
    const userData = (
      await getUserData({ onError: (error) => console.log(error) })
    );



    const refCode = (
      await getRefCode({ onError: (error) => console.log(error) })
    );


    setRefCode(refCode.toString());



    setRefCount(userData.refCount.toString())





  }


  return (
    <Box py={6} sx={{ whiteSpace: 'wrap' }} maxW={450} mx={'auto'} w={'100%'}>


      <Heading
        noOfLines={3}
        maxW={'30ch'}
        size={'md'}>Earn <Box as="span" color={'blue.600'} px={1} fontWeight={'black'}>5%</Box>
        commission on each referral directly to your wallet.
      </Heading>

      <Box py={8}>
        <Box as='label' display={'block'} htmlFor="ref" fontWeight={'bold'} fontSize={'small'}>Referral link</Box>
        <InputGroup >
          <Input cursor={'pointer'} id="ref" value={value} onClick={onCopy} />
          <InputRightElement pointerEvents={'none'}>
            <FiCopy />
          </InputRightElement>
        </InputGroup>

        <Flex alignItems={'center'} gap={8} py={4}>
          <Badge ml='1' fontSize='large' colorScheme='twitter'>
            Referral count
          </Badge>
          <Text fontWeight={'bold'}>{refCount}</Text>
        </Flex>
      </Box>


      <Flex justify={'space-between'} alignItems={'center'}>
        <Text fontWeight={600} >Reward</Text>
        {/* <Text px={4} py={1} bgColor={'#1F2235'} fontWeight={'bold'} rounded={4}> */}
        {/* BNB rewards */}
        {/* {bnbReferral} <Box as="span" pl={1} color={'yellow.600'}>BNB</Box> */}
        {/* </Text> */}
        <Text px={4} py={1}
          bgColor={'#1F2235'}
          fontWeight={'bold'}
          rounded={4}>
          {/* USDT rewards */}
          {usdtReferral} <Box as="span" pl={1} color={'green.600'}>USDT</Box>
        </Text>
      </Flex>


    </Box>
  )
}

export default Affiliate