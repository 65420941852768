import {
  Box,
  Flex,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaArrowAltCircleRight, FaChartLine } from "react-icons/fa";
import buyingSteps from "../components/home/buyingSteps";
import HowToBuySteps from "../components/home/HowToBuyStep";
import { StyledButton } from "../components/styledButton";
import { StyleText } from "../components/Typography";
import Wrapper from "../components/Wrapper";

const BuyGuide = () => {
  const [contract, setContract] = React.useState({
    value: "0x737ed8c950b7F8e35ef23c85Caf449569663d9e6",
    copied: false,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  const toast = useToast();

  return (
    <Wrapper>
      <StyleText
        texttype="h1"
        id="howtobuy"
        pb='1rem'
        mx='auto'>
        How to buy
      </StyleText>
      <Box>
        {buyingSteps.map((step, index) => (
          <HowToBuySteps index={index + 1} step={step} key={step.title} />
        ))}
      </Box>

      <Box py={'1rem'}>
        <Flex
          m="2rem auto"
          bg={`linear-gradient(246deg, rgb(131, 58, 180) 0%, rgb(219, 110, 255) 50%, rgb(77, 187, 255) 100%)`}
          maxW="690px"
          p="1rem"
          borderRadius="20px"
        >
          <Input
            fontWeight="bold"
            bg="transparent"
            border="none"
            value={contract.value}
            readOnly
            p=".8rem 1rem"
          />
          <CopyToClipboard
            text="0x737ed8c950b7F8e35ef23c85Caf449569663d9e6"
            onCopy={() =>
              setContract((prev) => ({
                ...prev,
                copied: true,
                value: "0x737ed8c950b7F8e35ef23c85Caf449569663d9e6",
              }))
            }>
            <Button
              onClick={() => {
                toast({
                  title: "Copy to clipboard",
                  description: "contract has been copied to your clipboard",
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
              }}
              variant="outline"
              px="1.5rem"
              border="none"
              _hover={{
                bg: "",
                color: "#eeeeee",
              }}>
              Copy Contract
            </Button>
          </CopyToClipboard>
        </Flex>

        <Flex
          p="1rem 0rem"
          columnGap="1rem"
          flexWrap="wrap"
          rowGap="1rem"
          m="1rem auto"
          justifyContent="center"
          maxW="600px">
          <StyledButton variant="solid" color={'orange'}
            target="_blank"
            as={'a'}
            href="https://pancakeswap.finance/swap?outputCurrency=0x737ed8c950b7F8e35ef23c85Caf449569663d9e6">
            <span style={{ paddingRight: "12px" }}>Buy on Pancakeswap</span>
            <FaArrowAltCircleRight />
          </StyledButton>
          <StyledButton as={'a'} variant="outline" color={'purple'}
            target="_blank"
            href="https://poocoin.app/tokens/0x737ed8c950b7f8e35ef23c85caf449569663d9e6">
            <span style={{ paddingRight: "12px" }}> Check Chart</span>
            <FaChartLine />
          </StyledButton>
        </Flex>
      </Box>
    </Wrapper>
  );
};

export default BuyGuide;
