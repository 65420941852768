import { Box, Flex, Link, Text } from '@chakra-ui/react'
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaTelegram, FaTwitter, FaMedium } from 'react-icons/fa'
import Wrapper from './Wrapper'
import { Link as RouterLink } from 'react-router-dom'

const Footer = () => {
  return (
    <Wrapper animateOnce>
      <Box id="Community">
        <Flex
          color="#ffffff"
          justifyContent={'center'}
          wrap="wrap"
          columnGap={{ base: '30px', lg: "100px" }}
          fontSize={{ base: '2rem', md: '3rem' }}
          rowGap="1.5rem">
          <Link
            transition=".23s ease-in"
            _hover={{
              color: '#dddddd',
            }}
            href="https://twitter.com/VaseLabs"
            target="_blank">
            <FaTwitter />
          </Link>
          <Link
            transition=".23s ease-in"
            _hover={{
              color: '#dddddd',
            }}
            href="https://t.me/+TXW78t9fnZwxZjQ0"
            target="_blank">
            <FaTelegram />
          </Link>
          {/* <Link
            transition=".23s ease-in"
            _hover={{
              color: '#dddddd',
            }}
            href="https://www.tiktok.com/@vaselabs?_t=8XDqMXLNgMI&_r=1"
            target="_blank">
            <FaTiktok />
          </Link> */}
          <Link
            transition=".23s ease-in"
            _hover={{
              color: '#dddddd',
            }}
            href="https://www.instagram.com/vaselabs/"
            target="_blank">
            <FaInstagram />
          </Link>
          <Link
            transition=".23s ease-in"
            _hover={{
              color: '#dddddd',
            }}
            href="https://web.facebook.com/realvaselabs"
            target="_blank">
            <FaFacebook />
          </Link>
          <Link
            transition=".23s ease-in"
            _hover={{
              color: '#dddddd',
            }}
            href="https://www.linkedin.com/company/realvaselabs/"
            target="_blank">
            <FaLinkedin />
          </Link>
          <Link
            transition=".23s ease-in"
            _hover={{
              color: '#dddddd',
            }}
            href="https://medium.com/@realvaselabs"
            target="_blank">
            <FaMedium />
          </Link>
          <Link
            transition=".23s ease-in"
            _hover={{
              color: '#dddddd',
            }}
            href="mailto:Info@vaselabs.io"
            target="_blank">
            <FaEnvelope />
          </Link>
        </Flex>
      </Box>

      <Flex justify={'center'} gap={3} pt="3rem" pb="1rem" fontSize={'small'}  >
        <Link as={RouterLink} to={'privacy-policy'} textDecor={'underline'}>Privacy policy</Link>
        <Link as={RouterLink} to={'terms-of-use'} textDecor={'underline'}>Terms of use</Link>
      </Flex>
      <Box pt="1rem" pb="1rem">
        <Text textAlign="center" fontSize="14px" color="#ffffff">
          &copy; copyright 2024
        </Text>
      </Box>
    </Wrapper>
  )
}

export default Footer
