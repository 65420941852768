import { Box, Flex, Image } from "@chakra-ui/react";
import { StyleText } from "../Typography";
import Wrapper from "../Wrapper";

const VaseRaffle = () => {
	return (
		<Wrapper bgColor="rgba(66, 73, 255, 0.01)">
			<Flex alignItems={'flex-start'} columnGap='2.5rem' rowGap={'2rem'} flexDirection={{ base: 'column-reverse', lg: 'row' }} >
				<Box>
					<Image
						src={'https://firebasestorage.googleapis.com/v0/b/seam-rent.appspot.com/o/vase%2Fraffle.png?alt=media&token=967872fa-8603-415f-a2ae-572c59265a5d'}
						maxW={{ md: "495.69px", base: "325px" }}
						maxH={{ md: "394px", base: "325px" }}
						w="100%"
						h="100%"
					/>
				</Box>

				<Box>
					<StyleText texttype="h2" pb=".5rem">
						Vase Raffle
					</StyleText>
					<StyleText texttype="bodyBold" pb='1rem'>
						Every Week  could be your lucky week to win big
					</StyleText>
					<StyleText texttype="bodyText" pb='1rem'>
						Join Vase Raffle: The Best Blockchain Raffle system. Get lucky,pick the winning ticket and win lots of money paid to your wallet  seamlessly.
					</StyleText>
					{/* <Button variant={'solid'} borderRadius='8px' colorScheme={'blackAlpha'}>
						Buy Ticket
					</Button> */}
				</Box>
			</Flex>
		</Wrapper>
	);
};

export default VaseRaffle;
